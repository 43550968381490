import { Component } from 'react';
import PropTypes from 'prop-types';
import { captureException, SEVERITY } from 'src/lib/sentry';

class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true };
  }

  state = { hasError: false };

  componentDidCatch(error, errorExtra) {
    const { level, extra } = this.props;
    let errorInfo;
    try {
      errorInfo = { ...(errorExtra || {}), ...extra };
    } catch (err) {}
    captureException(error, {
      errorInfo,
      level,
    });
  }

  render() {
    const { hasError } = this.state;
    const { renderError, children } = this.props;
    if (hasError) return renderError();
    return children;
  }
}

ErrorBoundary.displayName = 'ErrorBoundary';

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  renderError: PropTypes.func,
  level: PropTypes.oneOf(Object.values(SEVERITY)),
  extra: PropTypes.object,
};

ErrorBoundary.defaultProps = {
  children: null,
  renderError: () => null,
  level: undefined,
  extra: {},
};

ErrorBoundary.SEVERITY = SEVERITY;

export { ErrorBoundary };
