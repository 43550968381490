import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useAnalytics } from './useAnalytics';
import { FS } from 'src/general/constants/finiteStates';

/**
 * @note: PageAnalytics should only used in _app
 */
function PageAnalytics() {
  const { state, trackPageView } = useAnalytics();
  const router = useRouter();
  const pathname = router && router.pathname;

  useEffect(() => {
    const analyticsReady = state === FS.SUCCESS;

    if (analyticsReady && pathname) {
      trackPageView();
    }
  }, [state, trackPageView, pathname]);

  return null;
}

PageAnalytics.propTypes = {
  children: PropTypes.node,
};

PageAnalytics.defaultProps = {
  children: null,
};

export { PageAnalytics };
