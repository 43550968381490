import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import 'src/config/fcl/fcl'; // Imports environment variables and configures FCL
import 'src/lib/translations/i18n';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import { ChakraProvider } from '@chakra-ui/react';
import { Fonts, theme } from 'src/theme';
import { SessionProvider } from 'src/edge/session';
import LayoutWrapper from 'src/general/components/Layouts/LayoutWrapper';
import { ErrorBoundaryPage } from 'src/general/components/ErrorBoundary/ErrorBoundaryPage';
import { welcomeConsole } from 'src/general/utils/welcome';

type NFTHypeAppProps = AppProps & { flags: any };

function NFTHypeApp({
  Component,
  pageProps,
  flags,
}: NFTHypeAppProps): JSX.Element {
  useEffect(() => {
    welcomeConsole();
  }, []);

  return (
    <SessionProvider>
      <ChakraProvider theme={theme}>
        <Fonts />
        <ErrorBoundaryPage>
          {/* <ModalContextProvider> */}
          <LayoutWrapper {...pageProps}>
            {/* @ts-ignore */}
            <Component {...pageProps} />
          </LayoutWrapper>

          {/* <MixpanelScript />
                <CustomModal /> */}
          {/* </ModalContextProvider> */}
        </ErrorBoundaryPage>
      </ChakraProvider>
    </SessionProvider>
  );
}

NFTHypeApp.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  pageProps: PropTypes.object.isRequired,
};

export default NFTHypeApp;
