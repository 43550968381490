import { ThemeOverride } from '@chakra-ui/react';

export const Tabs: ThemeOverride['Tabs'] = {
  parts: ['tab', 'tablist', 'tabpanel'],
  baseStyle: {
    tab: {
      color: 'grey.8',
      fontSize: '14px',
      padding: 0,
    },
  },

  variants: {
    default: {
      tablist: {
        borderBottom: '2px solid #292929',
        gridGap: 5,
      },
      tab: {
        marginBottom: '-2px',
        px: 0,
        py: 3,
        color: 'white',
        borderBottomWidth: '2px',
        borderColor: 'transparent',
        _selected: {
          color: 'white',
          boxShadow: 'none',
          borderBottomWidth: '2px',
          borderColor: 'currentColor',
        },
      },
      tabpanel: {
        px: 0,
        pt: 4,
      },
    },
    uppercase: {
      tab: {
        textTransform: 'uppercase',
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {
    variant: 'default',
  },
};
