import * as React from 'react';
import { chakra, cx, forwardRef, useStyles } from 'src/theme';
import type { HTMLChakraProps, ThemingProps } from 'src/theme';

export interface NavListItemProps
  extends HTMLChakraProps<'li'>,
    ThemingProps<'li'> {
  children: React.ReactNode;
  className?: string;
}

export const NavListItem = forwardRef<NavListItemProps, 'li'>(
  ({ className, ...props }, ref) => {
    const styles = useStyles();

    return (
      <chakra.li
        className={cx('AD-nav-list-item', className)}
        ref={ref}
        __css={styles.listItem}
        {...props}
      />
    );
  },
);
