/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Comparator {
  EQUAL = "EQUAL",
  GREATER_THAN = "GREATER_THAN",
  GREATER_THAN_OR_EQUAL = "GREATER_THAN_OR_EQUAL",
  LESS_THAN = "LESS_THAN",
  LESS_THAN_OR_EQUAL = "LESS_THAN_OR_EQUAL",
  NOT_EQUAL = "NOT_EQUAL",
}

export enum Currency {
  USD = "USD",
}

export enum CursorDirection {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum DistributionEditionSortType {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export enum DistributionImageType {
  BACKGROUND = "BACKGROUND",
  DEFAULT = "DEFAULT",
}

export enum DistributionSortType {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export enum DistributionState {
  COMPLETE = "COMPLETE",
  INITIALIZED = "INITIALIZED",
  INVALID = "INVALID",
}

export enum DistributionTier {
  COMMON = "COMMON",
  FANDOM = "FANDOM",
  LEGENDARY = "LEGENDARY",
  PLAYOFFS = "PLAYOFFS",
  PREMIUM = "PREMIUM",
  RARE = "RARE",
  STANDARD = "STANDARD",
  ULTIMATE = "ULTIMATE",
}

export enum DistributionVideoType {
  RIP = "RIP",
}

export enum DropStatus {
  inProgress = "inProgress",
  past = "past",
  upcoming = "upcoming",
}

export enum MarketplaceEditionSortType {
  LISTED_DATE_ASC = "LISTED_DATE_ASC",
  LISTED_DATE_DESC = "LISTED_DATE_DESC",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
  SERIAL_ASC = "SERIAL_ASC",
}

export enum MomentNFTListingsSortType {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
  SERIAL_NUMBER_ASC = "SERIAL_NUMBER_ASC",
  SERIAL_NUMBER_DESC = "SERIAL_NUMBER_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export enum MomentNFTSaleSortType {
  ACQUIRED_AT_ASC = "ACQUIRED_AT_ASC",
  ACQUIRED_AT_DESC = "ACQUIRED_AT_DESC",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
}

export enum MomentNFTSortType {
  ACQUIRED_AT_ASC = "ACQUIRED_AT_ASC",
  ACQUIRED_AT_DESC = "ACQUIRED_AT_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DEFAULT = "DEFAULT",
  LISTED_PRICE_ASC = "LISTED_PRICE_ASC",
  LISTED_PRICE_DESC = "LISTED_PRICE_DESC",
  SERIAL_NUMBER_ASC = "SERIAL_NUMBER_ASC",
  SERIAL_NUMBER_DESC = "SERIAL_NUMBER_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export enum PackNFTSortType {
  ACQUIRED_AT_ASC = "ACQUIRED_AT_ASC",
  ACQUIRED_AT_DESC = "ACQUIRED_AT_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  OPENED_AT_ASC = "OPENED_AT_ASC",
  OPENED_AT_DESC = "OPENED_AT_DESC",
  REVEALED_AT_ASC = "REVEALED_AT_ASC",
  REVEALED_AT_DESC = "REVEALED_AT_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export enum PackNFTStatus {
  OPENED = "OPENED",
  REVEALED = "REVEALED",
  SEALED = "SEALED",
}

export enum PlayClassification {
  PLAYER_GAME = "PLAYER_GAME",
  PLAYER_MELT = "PLAYER_MELT",
  TEAM_GAME = "TEAM_GAME",
  TEAM_MELT = "TEAM_MELT",
}

export enum PlayImageType {
  PLAY_IMAGE_TYPE_CROPPED_ASSET = "PLAY_IMAGE_TYPE_CROPPED_ASSET",
  PLAY_IMAGE_TYPE_NIL = "PLAY_IMAGE_TYPE_NIL",
}

export enum PlayState {
  PLAY_STATUS_DRAFT = "PLAY_STATUS_DRAFT",
  PLAY_STATUS_PUBLISHED = "PLAY_STATUS_PUBLISHED",
}

export enum PlayType {
  PLAY_TYPE_AVATAR_NIL = "PLAY_TYPE_AVATAR_NIL",
  PLAY_TYPE_AVATAR_STATUE = "PLAY_TYPE_AVATAR_STATUE",
  PLAY_TYPE_AVATAR_WEARABLE = "PLAY_TYPE_AVATAR_WEARABLE",
}

export enum PlayVideoType {
  PLAY_VIDEO_TYPE_NIL = "PLAY_VIDEO_TYPE_NIL",
  PLAY_VIDEO_TYPE_SQUARE = "PLAY_VIDEO_TYPE_SQUARE",
  PLAY_VIDEO_TYPE_VERTICAL = "PLAY_VIDEO_TYPE_VERTICAL",
}

export enum QueueStatus {
  ENDED = "ENDED",
  PAUSED = "PAUSED",
  RUNNING = "RUNNING",
}

export enum Tier {
  BASE = "BASE",
  COMMON = "COMMON",
  FANDOM = "FANDOM",
  LEGENDARY = "LEGENDARY",
  PLAYOFFS = "PLAYOFFS",
  RARE = "RARE",
  SPECIAL_EDITION = "SPECIAL_EDITION",
  ULTIMATE = "ULTIMATE",
}

export enum ValueType {
  BOOLEAN = "BOOLEAN",
  INT = "INT",
  STRING = "STRING",
}

export interface AuthorizeCustomerSupportPackNFTReservationInput {
  signable: string;
}

export interface AuthorizeMomentNFTPurchaseInput {
  signable: string;
}

export interface AuthorizePackNFTReservationInput {
  signable: string;
}

export interface BaseSearchInput {
  pagination: PaginationInput;
}

export interface DistributionEditionFilters {
  byIDs?: (string | null)[] | null;
  byDistributionFlowIDs?: number[] | null;
  byEditionFlowIDs?: number[] | null;
}

export interface DistributionFilters {
  byIDs?: (string | null)[] | null;
  byFlowIDs?: number[] | null;
  byDropStatus?: (DropStatus | null)[] | null;
}

export interface PaginationInput {
  cursor: any;
  direction: CursorDirection;
  limit: number;
}

export interface ReservePackNFTInput {
  distributionFlowID: number;
  fullQueueID?: string | null;
}

export interface SearchDistributionEditionsInput {
  base?: BaseSearchInput | null;
  sortBy?: DistributionEditionSortType | null;
  filters?: DistributionEditionFilters | null;
}

export interface SearchDistributionsInput {
  base?: BaseSearchInput | null;
  sortBy?: DistributionSortType | null;
  filters?: DistributionFilters | null;
}

export interface SearchUserProfilesFilters {
  byUsername?: string | null;
}

export interface SearchUserProfilesInput {
  after?: string | null;
  first?: number | null;
  filters?: SearchUserProfilesFilters | null;
}

export interface UpsertUserProfileInput {
  dapperID: string;
  email?: string | null;
  phoneNumber?: string | null;
  username?: string | null;
  flowAddress?: string | null;
  profileImageUrl?: string | null;
  isSigned?: boolean | null;
  isMarketingAllowed?: boolean | null;
  hasCompletedFTUE?: boolean | null;
}

export interface ValidateCustomerSupportPackNFTReservationInput {
  reservationID: string;
  distributionFlowID: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
