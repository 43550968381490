import { ApolloClient } from '@apollo/client';
import { State } from 'xstate';

export enum FS {
  LOADING = 'LOADING',
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

export enum EVENTS {
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
}

export type Events = {
  type: EVENTS | keyof typeof EVENTS;
  data?: any;
  returnTo?: string;
};
export interface Wallet {
  [index: string]: any;
}
export interface Context {
  wallet: Wallet;
  client: ApolloClient<object>;
  error: any;
}

export interface Schema {
  states: {
    LOADING: {};
    AUTHENTICATED: {
      states: {
        IDLE: {};
        SIGN_OUT: {};
      };
    };
    UNAUTHENTICATED: {
      states: {
        IDLE: {};
        SIGN_IN: {};
      };
    };
  };
}

export interface TypeState {
  value: FS;
  context: Context;
}

export type MachineType = State<Context, Events, Schema, TypeState>;
