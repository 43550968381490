import * as React from 'react';
import type { HTMLChakraProps, ThemingProps } from 'src/theme';
import {
  useMultiStyleConfig,
  chakra,
  forwardRef,
  omitThemingProps,
  StylesProvider,
  cx,
} from 'src/theme';
import { NavContextProvider } from './';

export interface NavProps extends HTMLChakraProps<'nav'>, ThemingProps<'nav'> {
  children: React.ReactNode;
  variant?: 'global' | 'sub';
  size?: 'sm' | 'md';
  activeHref?: string;
}

export const Nav = forwardRef<NavProps, 'nav'>((props: NavProps, ref) => {
  const { variant = 'sub', size = 'sm' } = props;
  const styles = useMultiStyleConfig('Nav', {
    variant,
    size,
  });

  const { children, className, activeHref, ...rest } = omitThemingProps(props);

  return (
    <NavContextProvider activeHref={activeHref}>
      <StylesProvider value={styles}>
        <chakra.nav
          sx={styles.nav}
          ref={ref}
          className={cx('AD-nav', className)}
          __css={styles.root}
          {...rest}
        >
          {children}
        </chakra.nav>
      </StylesProvider>
    </NavContextProvider>
  );
});

Nav.displayName = 'Nav';
