import { ReactNode } from 'react';
import { Footer } from 'src/general/components/Footer';
import DefaultNavigation from 'src/general/components/Navigations/Default';
import { Box, Flex } from 'src/theme';

function DefaultLayout({ children }: { children: ReactNode }) {
  return (
    <Flex h="100%" flexDirection="column" className="default-layout">
      <DefaultNavigation />
      <Box
        flexGrow={1}
        flexShrink={0}
        padding={0}
        // paddingX={8}
        w="100%"
        minHeight={{ base: '40vh', md: '60vh' }}
        alignSelf="center"
      >
        {children}
      </Box>
      <Footer />
    </Flex>
  );
}

export default DefaultLayout;
