import { useRouter } from 'next/router';
import { Link } from 'src/general/components/Link/Link';
import { IconExternalLink } from 'src/theme';
import { NavItem } from './navItems';
import Typography from 'src/general/components/Typography';
import { FONT_SIZE } from 'src/general/components/Typography/Typography';
import { css } from '@emotion/css';

function NavLink({ href, children, hasExternalLinkIcon, ...rest }: NavItem) {
  const router = useRouter();
  const styles =
    router.pathname === href
      ? css`
          border-bottom: 3px solid #fd003c;
          padding: 11px 0px 8px;
        `
      : css``;

  return (
    <Link
      key={href}
      variant="nav-link"
      href={href}
      display="flex"
      alignItems="center"
      boxShadow="none !important"
      {...rest}
    >
      <Typography fontSize={FONT_SIZE.sm} css={styles}>
        {children}
      </Typography>
      {hasExternalLinkIcon && (
        <IconExternalLink h="16px" w="16px" ml={2} color="grey.text" />
      )}
    </Link>
  );
}

export default NavLink;
