import {
  ComponentSingleStyleConfig,
  ThemeOverride,
  CSSObject,
} from '@chakra-ui/react';
import { Props } from '../../Button/Button';
import { shapiroLight } from 'src/theme/Fonts/typeface';

const activeStyles: CSSObject = {
  backgroundColor: 'grey.7',
};
const outlineStyles: CSSObject = {
  background: 'transparent',
  border: '1px solid white',
  color: 'white',
  _disabled: {
    bgColor: 'grey.mid',
    color: 'grey.7',
  },
  _hover: {
    background: 'var(--chakra-colors-grey-7)',
    color: 'dark',
    textDecoration: 'none',
  },
  _active: {
    background: 'var(--chakra-colors-grey-7)',
    color: 'dark',
    textDecoration: 'none',
  },
};

const _Button: ComponentSingleStyleConfig = {
  baseStyle: {
    ...shapiroLight,
    h: 'auto',
    backgroundColor: 'white',
    color: 'dark',
    borderRadius: '40px',
    position: 'relative',
    textTransform: 'uppercase',
    lineHeight: '120%',
    fontWeight: 'medium',
    fontSize: '14px',
    _disabled: {
      opacity: '0.9',
      borderColor: 'grey.mid',
      bgColor: 'grey.5',
      color: 'grey.text',
      _before: {
        content: 'unset',
      },
      _hover: {
        backgroundColor: 'grey.7',
      },
      _focus: {
        transform: 'unset',
      },
    },
    _hover: activeStyles,
  },
  sizes: {
    xsm: {
      px: '2',
      height: '32px',
      fontSize: 'xs',
    },
    sm: {
      px: '2',
      height: '36px',
      fontSize: 'xs',
    },
    md: {
      px: '4',
      height: '42px',
      fontSize: 'sm',
      width: 'min-content',
    },
    lg: {
      px: '6',
      height: '48px',
      fontSize: 'md',
    },
    xl: {
      px: '20',
      height: '64px',
      fontSize: 'md',
    },
  },
  variants: {
    solid: ({ colorScheme }: Props) => ({
      bgColor: colorScheme === 'grey' ? 'grey.4' : 'white',
      color: 'dark',
    }),
    outline: outlineStyles,
    primary: {
      _focus: {
        opacity: '0.8',
      },
      _hover: {
        bgColor: 'grey.7',
        _disabled: {
          bgColor: 'grey.mid',
          color: 'grey.7',
        },
        textDecoration: 'none',
      },
    },
    secondary: {
      bgColor: 'grey.1',
      color: 'white',
      _focus: {
        bgColor: 'grey.1',
      },
      _hover: {
        bgColor: 'grey.1',
        _disabled: {
          bgColor: 'grey.mid',
          color: 'grey.7',
        },
      },
    },
    cancel: {
      bgColor: 'backgroundRed',
      color: 'alert',
      _focus: {
        bgColor: 'backgroundRed',
        boxShadow: '0 0 0 3px var(--chakra-colors-alert)',
      },
      _hover: {
        bgColor: 'backgroundRed',
      },
    },
    link: {
      size: 'md',
      p: 3,
      bgColor: 'transparent',
    },
  },
  defaultProps: {
    variant: 'solid',
    size: 'md',
    colorScheme: 'black',
  },
};

// We need this hacky TS workaround because the Chakra UI types aren't properly extensible. When we deviate too much from
// the default properties of the `Button` theme, we get false positive type errors.
// https://github.com/chakra-ui/chakra-ui/issues/4497
export const Button = _Button as ThemeOverride['components']['Button'];
