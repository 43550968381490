import UFCStrikeSVG from './ufc-strike.svg';
//import UFCStrikeUnderlinedSVG from './ufc-strike-underlined.svg';
import UFCStrikeUnderlinedPNG from './ufc-strike-underlined.png';

export const UFCStrikeLogo = () => <UFCStrikeSVG />;
// TODO: Replace underlined version with SVG.
// Currently when using both SVGs for some reason 1st one replaces others on the frontend (id's in SVGs are different)
//export const UFCStrikeUnderlinedLogo = () => (<UFCStrikeUnderlinedSVG />);
export const UFCStrikeUnderlinedLogo = () => (
  <img
    alt="UFC Strike Logo"
    src={UFCStrikeUnderlinedPNG.src}
    height="16px"
    width="102px"
  />
);
