import { getItem, setItem } from 'src/general/utils/localStorage';
import { enhancedT } from './enhancedT';

export const useTranslation = () => {
  const ssr = typeof window === 'undefined';

  // @todo: make this generic later
  if (!ssr) {
    window.toggleLokaliseDebugger = () => {
      const lokaliseOutput = getItem('lokaliseOutput');
      // @note: local storage does not return booleans
      if (lokaliseOutput === 'true') {
        setItem('lokaliseOutput', 'false');
      } else {
        // @note: for false, null, and undefined we flip it to true;
        setItem('lokaliseOutput', 'true');
      }
    };
    window.toggleLokaliseKeys = () => {
      const lokaliseKeys = getItem('lokaliseKeys');
      // @note: local storage does not return booleans
      if (lokaliseKeys === 'true') {
        setItem('lokaliseKeys', 'false');
      } else {
        // @note: for false, null, and undefined we flip it to true;
        setItem('lokaliseKeys', 'true');
      }
    };
  }
  return { t: enhancedT };
};
