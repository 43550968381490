import { Global } from '@emotion/react';

// @NOTE: you can add fonts in here as necessary, see code samples at the bottom of the file:

function Fonts() {
  return (
    <Global
      styles={`
        /* Shapiro */
        
        @font-face {
          font-display: swap;
          font-family: 'Shapiro-feather';
          src: url('/static/fonts/Shapiro/Shapiro-35-Feather.otf') format('opentype');
        }
        @font-face {
          font-display: swap;
          font-family: 'Shapiro-light';
          src: url('/static/fonts/Shapiro/Shapiro-65-Light.otf') format('opentype');
        }
        @font-face {
          font-display: swap;
          font-family: 'Shapiro-heavy';
          src: url('/static/fonts/Shapiro/Shapiro-75-Heavy.otf') format('opentype');
        }
        @font-face {
          font-display: swap;
          font-family: 'Shapiro-super';
          src: url('/static/fonts/Shapiro/Shapiro-95-Super.otf') format('opentype');
        }
        @font-face {
          font-display: swap;
          font-family: 'Shapiro-air';
          src: url('/static/fonts/Shapiro/Shapiro-97-Air.ttf') format('truetype');
        }
        @font-face {
          font-display: swap;
          font-family: 'Shapiro-light-heavy';
          src: url('/static/fonts/Shapiro/Shapiro-65-Light-Heavy.otf') format('opentype');
        }

        /* Druk */
        
        @font-face {
          font-display: swap;
          font-family: 'Druk';
          font-weight: 400;
          font-style: normal;
          src: url('/static/fonts/Druk/Druk-Medium-Web.woff2') format('woff2');
        }
        @font-face {
          font-display: swap;
          font-family: 'Druk';
          font-weight: 700;
          font-style: normal;
          src: url('/static/fonts/Druk/Druk-Bold-Web.woff2') format('woff2');
        }
        @font-face {
          font-display: swap;
          font-family: 'Druk';
          font-weight: 700;
          font-style: italic;
          src: url('/static/fonts/Druk/Druk-BoldItalic-Web.woff2') format('woff2');
        }

        /* State Wide */

        @font-face {
          font-display: swap;
          font-family: 'StateWide-Black';
          src: url('/static/fonts/State-Wide-Regular/StateWide-Black.otf');
        }
        @font-face {
          font-display: swap;
          font-family: 'StateWide-BlackItalic';
          src: url('/static/fonts/State-Wide-Regular/StateWide-BlackItalic.otf');
        }
        @font-face {
          font-display: swap;
          font-family: 'StateWide-Bold';
          src: url('/static/fonts/State-Wide-Regular/StateWide-Bold.otf');
        }
        @font-face {
          font-display: swap;
          font-family: 'StateWide-BoldItalic';
          src: url('/static/fonts/State-Wide-Regular/StateWide-BoldItalic.otf');
        }
        @font-face {
          font-display: swap;
          font-family: 'StateWide-ExtraBold';
          src: url('/static/fonts/State-Wide-Regular/StateWide-ExtraBold.otf');
        }
        @font-face {
          font-display: swap;
          font-family: 'StateWide-ExtraBoldItalic';
          src: url('/static/fonts/State-Wide-Regular/StateWide-ExtraBoldItalic.otf');
        }
        @font-face {
          font-display: swap;
          font-family: 'StateWide-Italic';
          src: url('/static/fonts/State-Wide-Regular/StateWide-Italic.otf');
        }
        @font-face {
          font-display: swap;
          font-family: 'StateWide-Light';
          src: url('/static/fonts/State-Wide-Regular/StateWide-Light.otf');
        }
        @font-face {
          font-display: swap;
          font-family: 'StateWide-LightItalic';
          src: url('/static/fonts/State-Wide-Regular/StateWide-LightItalic.otf');
        }
        @font-face {
          font-display: swap;
          font-family: 'StateWide-Medium';
          src: url('/static/fonts/State-Wide-Regular/StateWide-Medium.otf');
        }
        @font-face {
          font-display: swap;
          font-family: 'StateWide-MediumItalic';
          src: url('/static/fonts/State-Wide-Regular/StateWide-MediumItalic.otf');
        }
        @font-face {
          font-display: swap;
          font-family: 'StateWide-Regular';
          src: url('/static/fonts/State-Wide-Regular/StateWide-Regular.otf');
        }
        @font-face {
          font-display: swap;
          font-family: 'StateWide-SemiBold';
          src: url('/static/fonts/State-Wide-Regular/StateWide-Semi-Bold.otf');
        }
        @font-face {
          font-display: swap;
          font-family: 'StateWide-SemiBoldItalic';
          src: url('/static/fonts/State-Wide-Regular/StateWide-Semi-BoldItalic.otf');
        }
      `}
    />
  );
}
export { Fonts };
