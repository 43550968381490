import { useState, useEffect, useContext, createContext, FC } from 'react';
import { useSessionMachine } from '../fsm/machine';
import { currentUser } from '@onflow/fcl';
import { Wallet } from '../fsm/types';

type SessionMachineReturnType = ReturnType<typeof useSessionMachine>;

interface Context {
  // there is an odd TS issue where the it keep expecting `value` to be a `string`, even though we have a substate node
  // in this case, e.g. `{ value: { wallet }}`. We override to `any` for now
  state: SessionMachineReturnType[0] & { value: any };
  send: SessionMachineReturnType[1];
}

export const SessionContext = createContext<Context>({} as Context);

export const SessionProvider: FC = ({ children }) => {
  const [state, send] = useSessionMachine();

  const [wallet, setWallet] = useState<Wallet>();

  useEffect(
    () => currentUser().subscribe(setWallet),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const sessionValue = {
    state,
    send,
    wallet,
  };

  return (
    <SessionContext.Provider value={sessionValue}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);
