import React from 'react';
import styled from '@emotion/styled';

const agnosticStyled = styled(({ tag = 'p', children, ...props }) =>
  React.createElement(tag, props, children),
);

// put this values into styled-components theme
export enum FONT_COLORS {
  'BLACK' = '#000',
  'WHITE' = '#ffffff',
  'CADET_BLUE' = '#A7AEBB',
  'FAUX_ALICE_BLUE' = '#F0F2FD',
  'TORCH_RED' = '#FD003C',
}

export enum FONT_SIZE {
  'xxs' = '9px',
  'xs' = '12px',
  'sm' = '14px',
  'md' = '16px',
  'mdx' = '18px',
  'bg' = '20px',
  'hg' = '32px',
  'hgxxx' = '56px',
}

export enum FONT_FAMILY {
  'SHAPIRO' = 'Shapiro-light',
  'SHAPIRO_FEATHER' = 'Shapiro-feather',

  'STATE_WIDE_BLACK' = 'StateWide-Black',
  'STATE_WIDE_BLACK_ITALIC' = 'StateWide-BlackItalic',
  'STATE_WIDE_BOLD' = 'StateWide-Bold',
  'STATE_WIDE_BOLD_ITALIC' = 'StateWide-BoldItalic',
  'STATE_WIDE_EXTRA_BOLD' = 'StateWide-ExtraBold',
  'STATE_WIDE_EXTRA_BOLD_ITALIC' = 'StateWide-ExtraBoldItalic',
  'STATE_WIDE_ITALIC' = 'StateWide-Italic',
  'STATE_WIDE_LIGHT' = 'StateWide-Light',
  'STATE_WIDE_LIGHT_ITALIC' = 'StateWide-LightItalic',
  'STATE_WIDE_MEDIUM' = 'StateWide-Medium',
  'STATE_WIDE_MEDIUM_ITALIC' = 'StateWide-MediumItalic',
  'STATE_WIDE_REGULAR' = 'StateWide-Regular',
  'STATE_WIDE_SEMI_BOLD' = 'StateWide-SemiBold',
  'STATE_WIDE_SEMI_BOLD_ITALIC' = 'StateWide-SemiBoldItalic',
}

// Add breakpoints and props like marginRightXS, marginRightSM, marginRightMD etc
const Typography = agnosticStyled`
color: ${({
  color = '#ffffff',
}: {
  color?: FONT_COLORS | string;
  fontSize?: FONT_SIZE | string;
  fontFamily?: FONT_FAMILY;
  marginRight?: string;
  marginLeft?: string;
  marginBottom?: string;
  marginTop?: string;
  textTransform?: string;
}) => color};
  font-size: ${({ fontSize = FONT_SIZE.md }) => fontSize};
  line-height: ${({ fontSize }) => {
    switch (fontSize) {
      case FONT_SIZE.hgxxx:
        return '72px';
      case FONT_SIZE.hg:
        return '43.2px';
      case FONT_SIZE.bg:
        return '27px';
      case FONT_SIZE.mdx:
        return '24px';
      case FONT_SIZE.md:
        return '20px';
      case FONT_SIZE.sm:
        return '18px';
      case FONT_SIZE.xs:
        return '14px';
      case FONT_SIZE.xxs:
        return '9px';
      default:
        return '18px';
    }
  }};
  font-family: ${({ fontFamily = FONT_FAMILY.SHAPIRO }) => fontFamily};
  margin-right: ${({ marginRight = '0px' }) => marginRight};
  margin-left: ${({ marginLeft = '0px' }) => marginLeft};
  margin-bottom: ${({ marginBottom = '0px' }) => marginBottom};
  margin-top: ${({ marginTop = '0px' }) => marginTop};
  margin-left: ${({ marginLeft = '0px' }) => marginLeft};
  text-transform: ${({ textTransform = '' }) => textTransform};

  ${({ css }) => css}`;

export default Typography;
