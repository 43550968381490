import { Icon as ChakraIcon, IconProps, ChakraTheme } from '@chakra-ui/react';
import { InterpolationWithTheme } from '@emotion/core';

import {
  ArrowForwardIcon,
  CalendarIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  ChevronDown,
  Download,
  AtSign,
  Filter,
  User,
  Instagram,
  Linkedin,
  Twitter,
  Share2,
  Maximize2,
  X as Close,
  AlertTriangle,
  Check,
  ExternalLink,
} from 'react-feather';

import YouTubeSvg from './svg/youtube.svg';
import InstagramSvg from './svg/instagram.svg';
import DiscordSvg from './svg/discord.svg';
import TwitterSvg from './svg/twitter.svg';
import FacebookSvg from './svg/facebook.svg';
import TwitchSvg from './svg/twitch.svg';
import HamburgerMenuSvg from './svg/hamburger-menu.svg';
import ClockSvg from './svg/clock.svg';
import InfoSvg from './svg/info.svg';
import OwnedSvg from './svg/ownedIcon.svg';
import UnavailableSvg from './svg/unavailableIcon.svg';
import HiddenSvg from './svg/hiddenIcon.svg';
import BurnedSvg from './svg/burnedIcon.svg';
import BornSvg from './svg/bornIcon.svg';
import HometownSvg from './svg/hometownIcon.svg';
import DraftSvg from './svg/draftIcon.svg';
import RarityCommonSvg from './svg/rarityCommon.svg';
import RarityLegendarySvg from './svg/rarityLegendary.svg';
import RarityUltimateSvg from './svg/rarityUltimate.svg';
import RarityRareSvg from './svg/rarityRare.svg';
import GearSvg from './svg/gearIcon.svg';
import FootballIconSvg from './svg/football-icon.svg';
import DropsAnnouncementIcon from './svg/dropsAnnouncementIcon.svg';
import MarketIcon from './svg/marketIcon.svg';
import CheckmarkSvg from './svg/checkmark.svg';
import TriangleSvg from './svg/triangle.svg';
import RightArrow from './svg/rightArrow.svg';
import RefreshSvg from './svg/refreshIcon.svg';
import PacksSVG from './svg/packsIcon.svg';
import MomentsSVG from './svg/momentsIcon.svg';
import PaginationArrowLeftSVG from './svg/paginationArrowLeft.svg';
import PaginationArrowRightSVG from './svg/paginationArrowRight.svg';
import ScrollIndicatorSVG from './svg/scroll-indicator.svg';
import VolumeOnSvg from './svg/volumeOn.svg';
import VolumeOffSvg from './svg/volumeOff.svg';
import FilterIcon from './svg/filterIcon.svg';
import RepeatSvg from './svg/repeatIcon.svg';
import LogoutSvg from './svg/logout.svg';
import WalletSvg from './svg/wallet.svg';

type ChakraIconProps = IconProps & {
  css?: InterpolationWithTheme<ChakraTheme>;
};

export const ICON_ARROW_FORWARD = {
  as: ArrowForwardIcon,
  w: '20px',
  h: '20px',
  alt: 'Next',
};
export const ICON_CHECK = {
  as: Check,
  w: '16px',
  h: '16px',
  alt: 'Checkmark',
};
export const ICON_CHEVRON_DOWN = {
  as: ChevronDown,
  w: '24px',
  h: '24px',
  alt: 'Chevron Down',
};
export const ICON_DOWNLOAD = {
  as: Download,
  w: '34px',
  h: '34px',
  alt: 'Download it',
};
export const ICON_ATSIGN = {
  as: AtSign,
  w: '44px',
  h: '35px',
  alt: 'Share view Email',
};
export const ICON_FILTER = {
  as: Filter,
  w: '16px',
  h: '15px',
  alt: 'Filter',
};
export const ICON_USER = {
  as: User,
  w: '21px',
  h: '23px',
  alt: 'Show user menu',
};
export const ICON_LINKEDIN = {
  as: Linkedin,
  w: '17px',
  h: '17px',
  alt: 'Show Instagram',
};
export const ICON_INSTAGRAM = {
  as: Instagram,
  w: '17px',
  h: '17px',
  alt: 'Show Instagram',
};
export const ICON_TWITTER = {
  as: Twitter,
  w: '17px',
  h: '17px',
  alt: 'Show twitter',
};
export const ICON_X = {
  as: Close,
  w: '18px',
  h: '18px',
  alt: 'Close',
};

export const Icon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} />;
};

export const IconHamburgerMenu = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={HamburgerMenuSvg} />;
};

export const IconYouTubeLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={YouTubeSvg} />;
};

export const IconInstagramLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={InstagramSvg} />;
};

export const IconDiscordLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={DiscordSvg} />;
};

export const IconTwitterLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={TwitterSvg} />;
};

export const IconFacebookLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={FacebookSvg} />;
};

export const IconTwitchLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={TwitchSvg} />;
};

export const IconClock = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ClockSvg} />;
};

export const IconInfo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={InfoSvg} />;
};

export const OwnedEditionLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={OwnedSvg} />;
};

export const UnavailableEditionLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={UnavailableSvg} />;
};

export const HiddenEditionLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={HiddenSvg} />;
};

export const BurnedEditionLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={BurnedSvg} />;
};

export const IconBorn = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={BornSvg} />;
};

export const IconHometown = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={HometownSvg} />;
};

export const IconDraft = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={DraftSvg} />;
};

export const IconMaximize = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Maximize2} />;
};

export const IconShare = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Share2} />;
};

export const LegendaryIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={RarityLegendarySvg} />;
};

export const UltimateIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={RarityUltimateSvg} />;
};

export const RareIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={RarityRareSvg} />;
};

export const CommonIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={RarityCommonSvg} />;
};

export const IconGear = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={GearSvg} />;
};

export const IconFootball = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={FootballIconSvg} />;
};

export const IconDropsAnnouncement = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={DropsAnnouncementIcon} />;
};

export const IconDropsMarket = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={MarketIcon} />;
};

export const IconClose = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Close} />;
};

export const IconAlert = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={AlertTriangle} />;
};

export const IconCheckmark = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={CheckmarkSvg} />;
};

export const IconChevronDown = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ChevronDownIcon} />;
};

export const IconChevronLeft = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ChevronLeftIcon} />;
};

export const IconChevronRight = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ChevronRightIcon} />;
};

export const IconCalendar = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={CalendarIcon} />;
};

export const IconCheck = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Check} />;
};

export const IconTriangle = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={TriangleSvg} />;
};

export const IconRightArrow = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={RightArrow} />;
};

export const IconRefresh = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={RefreshSvg} />;
};
export const IconProfileMoments = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={MomentsSVG} />;
};
export const IconProfilePacks = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={PacksSVG} />;
};

export const IconPaginationArrowLeft = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={PaginationArrowLeftSVG} />;
};
export const IconPaginationArrowRight = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={PaginationArrowRightSVG} />;
};
export const IconScrollIndicator = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ScrollIndicatorSVG} />;
};

export const VolumeOn = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={VolumeOnSvg} />;
};
export const VolumeOff = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={VolumeOffSvg} />;
};
export const IconExternalLink = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ExternalLink} />;
};

export const IconFilter = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={FilterIcon} />;
};

export const RepeatIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={RepeatSvg} />;
};

export const LogoutIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={LogoutSvg} />;
};

export const WalletIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={WalletSvg} />;
};
