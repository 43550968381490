import { ThemeOverride } from '@chakra-ui/react';
import {
  druk,
  shapiroFeather,
  shapiroLightHeavy,
  shapiroHeavy,
} from 'src/theme/Fonts/typeface';

export const TextDeprecated: ThemeOverride['components']['Text'] = {
  sizes: {
    xxl: {
      fontSize: [24, null, 56, 80],
    },
    //bodyTextExtraLarge
    xl: {
      fontSize: [24, 24, 24, 20, 24],
    },
    // bodyTextLarge
    lg: {
      fontSize: 16,
    },
    // bodyTextMedium
    md: {
      fontSize: 14,
    },
    // bodyTextSmall
    sm: {
      fontSize: 12,
    },
    xs: {
      fontSize: 8,
    },
  },
  variants: {
    light: {
      ...shapiroFeather,
      lineHeight: 1.1,
    },
    // @TODO: this will fail on internet explorer. probably need to use emotion +
    // @supports to test this, like:
    /*
        @supports (-webkit-text-stroke: 1px white) {
          x {
            -webkit-text-stroke: 1px white;
            -webkit-text-fill-color: white;
          }
        }
      */
    link: {
      textTransform: 'uppercase',
      ...shapiroLightHeavy,
      textDecor: 'underline',
    },
    subtitle: {
      opacity: 0.4,
    },
    sparkle: {
      bg:
        'top right no-repeat url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYuNSAwYzAgMy45IDIuNiA2LjUgNi41IDYuNS0zLjkgMC02LjUgMi42LTYuNSA2LjUgMC0zLjktMi42LTYuNS02LjUtNi41IDMuOSAwIDYuNS0yLjYgNi41LTYuNXoiIGZpbGw9IiM2RjU3RkYiLz48L3N2Zz4=")',
      pr: 4,
      pt: 1,
    },
    bold: {
      ...shapiroLightHeavy,
    },
    // Labels
    labelBold: {
      ...shapiroLightHeavy,
      lineHeight: '17px',
      textTransform: 'uppercase',
    },
    label: {
      ...shapiroFeather,
      lineHeight: '17px',
      textTransform: 'uppercase',
    },
    dropCardTitle: {
      fontFamily: [shapiroLightHeavy.fontFamily, null, druk.fontFamily],
      fontWeight: 700,
      textTransform: ['none', null, 'uppercase'],
    },
    homepageTitle: {
      ...druk,
      fontWeight: 700,
      fontStyle: 'italic',
      fontSize: [40, 40, 56],
      textTransform: 'uppercase',
    },
    success: {
      ...druk,
      fontWeight: 700,
      fontStyle: 'italic',
      fontSize: 34,
      color: 'success',
      textTransform: 'uppercase',
    },
    // Pack details
    lowestAskCopy: {
      ...shapiroFeather,
      color: 'grey.text',
      fontSize: 15,
      lineHeight: 1.375,
    },
    body: {
      fontSize: 14,
      lineHeight: [1.2, 1],
      ...shapiroFeather,
    },
    block: {
      ...shapiroHeavy,
    },
    rare: {
      color: 'rare',
    },
    legendary: {
      color: 'legendary',
    },
    common: {
      color: 'common',
    },
  },
};
