import {
  druk,
  shapiroLight,
  shapiroLightHeavy,
} from 'src/theme/Fonts/typeface';

const _Heading = {
  baseStyle: {
    ...shapiroLight,
  },
  sizes: {
    xxl: {
      fontSize: '4rem',
    },
    xl: {
      fontSize: '3.375rem',
    },
    lg: {
      fontSize: '2.5rem',
    },
    md: {
      fontSize: '1.75rem',
    },
    sm: {
      fontSize: '1.5rem',
    },
    xs: {
      fontSize: '1.125rem',
    },
  },
  variants: {
    tall: {
      letterSpacing: '((1rem - 1em) * 0.052)',
      lineHeight: '1em',
      fontWeight: 900,
      color: 'white',
      textTransform: 'uppercase',
      ...druk,
    },
    short: {
      letterSpacing: '((1rem - 1em) * 0.052)',
      lineHeight: '1em',
      fontWeight: 900,
      color: 'white',
      ...druk,
    },
    tame: {
      letterSpacing: 'clamp(-1px, ((1rem - 1em) * 0.08), none)',
      fontWeight: 700,
      color: 'white',
      lineHeight: '1em + 6px',
      ...shapiroLightHeavy,
    },
  },
  defaultProps: { variant: 'short', size: 'xl' },
};

export const Heading = _Heading;
