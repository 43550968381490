import { useMachine } from '@xstate/react';
import { createMachine, MachineConfig } from 'xstate';
import { Context, Events, Schema, TypeState } from './types';
import { assign } from 'xstate';
import { authenticate, unauthenticate, currentUser } from '@onflow/fcl';

export const definition: MachineConfig<Context, Schema, Events> = {
  id: 'session',
  context: {
    wallet: undefined,
    client: undefined,
    error: undefined,
  },
  initial: 'LOADING',
  states: {
    LOADING: {
      invoke: {
        id: 'loadCurrentUser',
        src: async () => await currentUser().snapshot(),
        onDone: [
          {
            target: 'AUTHENTICATED',
            actions: assign({ wallet: (_, event: any) => event.data }),
            cond: (_, event: any) => !!event.data?.addr,
          },
          {
            target: 'UNAUTHENTICATED',
          },
        ],
        onError: {
          target: 'UNAUTHENTICATED',
          actions: assign({ error: (_, event: any) => event.data }),
        },
      },
    },
    AUTHENTICATED: {
      initial: 'IDLE',
      states: {
        IDLE: {
          on: {
            SIGN_OUT: 'SIGN_OUT',
          },
        },
        SIGN_OUT: {
          invoke: {
            id: 'signOut',
            src: async () => await unauthenticate(),
            onDone: {
              target: '#session.UNAUTHENTICATED',
              actions: assign({ wallet: () => null }),
            },
          },
        },
      },
    },
    UNAUTHENTICATED: {
      initial: 'IDLE',
      states: {
        IDLE: {
          on: {
            SIGN_IN: 'SIGN_IN',
          },
        },
        SIGN_IN: {
          invoke: {
            id: 'signIn',
            src: async () => await authenticate(),
            onDone: {
              target: '#session.LOADING',
            },
          },
        },
      },
    },
  },
};

const machine = createMachine<Context, Events, TypeState>(definition);

export const useSessionMachine = () => {
  return useMachine(machine, {
    context: {},
  });
};
