import {
  druk,
  shapiroAir,
  shapiroFeather,
  shapiroLight,
  shapiroLightHeavy,
  shapiroHeavy,
  shapiro,
} from 'src/theme/Fonts/typeface';

export const HeadingDeprecated = {
  baseStyle: {
    ...shapiroLight,
  },
  sizes: {
    '5xl': {
      fontSize: ['5.5rem', null, '6.25rem'],
      lineHeight: ['4.5rem', null, '5.3rem'],
    },
    // Heading 1
    '4xl': {
      fontSize: ['72px', null, '88px'],
      lineHeight: 1.1,
    },
    // Heading 2
    '3xl': {
      fontSize: ['60px', null, null, '70px', '80px'],
      lineHeight: '90%',
    },
    // Heading 3
    '2xl': {
      fontSize: ['40px', null, '56px'],
      lineHeight: 1.1,
    },
    // Heading 4
    xl: {
      fontSize: '32px',
      lineHeight: 1.1,
    },
    // desktopTitle1
    lg: {
      fontSize: [60, null, 64],
      lineHeight: ['4.5rem', null, '5.3rem'],
    },
    // desktopTitle2 / mobileTitle2
    md: {
      fontSize: [45, null, 48],
      lineHeight: ['3.31rem', null, '3.01rem'],
    },
    // desktopTitle4 / mobileTitle4
    sm: {
      fontSize: [30, null, 32],
      lineHeight: ['2.19rem', null, '2.38rem'],
    },
    // desktopTitle5 / mobileTitle5 / mobileTitle6
    xs: {
      fontSize: [20, null, null, 22, 24],
      lineHeight: [6, null, 7],
    },

    // Temp addding the sizes needed until properly implemented
    tempxl: {
      fontSize: '64px',
    },
    templg: {
      fontSize: '54px',
    },
    tempmlg: {
      fontSize: '40px',
    },
    tempmd: {
      fontSize: '28px',
    },
    tempsm: {
      fontSize: '24px',
    },
    tempxs: {
      fontSize: '18px',
    },
    // End temp
  },
  variants: {
    // Temp adding variants that I need early :p @Arnie remove these when you properly implement
    tall: {
      color: 'white',
      fontWeight: 900,
      // letterSpacing: '((1rem - 1em) * 0.052)',
      lineHeight: '1em',
      textTransform: 'uppercase',
      ...druk,
    },
    short: {
      color: 'white',
      fontWeight: 900,
      // letterSpacing: '((1rem - 1em) * 0.052)',
      lineHeight: '1em',
      // textTransform: 'capitalize',
      ...druk,
    },
    tame: {
      lineHeight: 'calc(1em + 6px)',
      letterSpacing: '-0.64px',
      // letterSpacing: 'clamp(-1px, ((1rem - 1em) * 0.08), none)',
      fontWeight: 700,
      color: 'white',
      ...shapiro,
    },
    // End temp

    title: {
      ...druk,
      textTransform: 'uppercase',
      letterSpacing: '0.015em',
    },
    bold: shapiroLightHeavy,
    superLight: {
      fontWeight: '212',
      ...shapiroAir,
    },
    uppercase: {
      textTransform: 'uppercase',
      lineHeight: ['2.4rem', null, 10],
    },
    light: {
      ...shapiroFeather,
      lineHeight: ['2.4rem', null, 10],
    },
    heavy: {
      ...shapiroHeavy,
      fontWeight: 400,
    },
    modal: {
      ...shapiroHeavy,
      fontWeight: 400,
    },
  },
};
