import { Link } from 'src/general/components/Link/Link';
import { home } from 'src/modules/routes';
import { Box, Flex, HStack, Stack } from 'src/theme';
import MobileNavigation from './MobileNavigation';
import { NavItems } from './navItems';
import NavLink from './NavLink';
import { NavItem } from './navItems';

const DefaultNavigation = () => {
  const navItems: NavItem[] = NavItems();

  return (
    <Flex
      bg="black"
      h="104px"
      alignItems="center"
      justifyContent={['space-between', 'space-between']}
      padding={0}
      paddingX={6}
    >
      <Flex
        w={['100%', '100%', 'auto']}
        justifyContent={['space-between', 'space-between']}
        alignItems="center"
      >
        <HStack direction="row" spacing={[4, undefined, 0]}>
          <Box display={[undefined, undefined, 'none']}>
            <MobileNavigation />
          </Box>
          <Link href={home()} paddingY={4} paddingX={4}>
            {/* <UFCStrikeLogo /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              width="152px"
              viewBox="0 0 317 48"
              fill="none"
            >
              <g clipPath="url(#clip0_12_2721)">
                <path
                  d="M103.52 1.84C101.701 2.84267 100.443 4.63466 99.7173 7.25866L90.6506 39.9147C90.5186 40.3809 90.4381 40.8602 90.4106 41.344C90.3864 41.8335 90.4331 42.3239 90.5493 42.8C90.7797 43.6159 91.1769 44.3752 91.7157 45.0297C92.2546 45.6843 92.9235 46.2199 93.68 46.6027C95.5873 47.5887 97.7153 48.0698 99.8613 48H125.563L128.48 37.504H109.696C109.15 37.5229 108.608 37.4017 108.123 37.152C107.931 37.0526 107.761 36.9154 107.623 36.7486C107.486 36.5818 107.383 36.3888 107.323 36.1813C107.296 36.0606 107.285 35.9369 107.291 35.8133C107.296 35.6962 107.314 35.5799 107.344 35.4667L113.696 12.5653C113.755 12.2812 113.872 12.0121 114.039 11.7746C114.206 11.537 114.419 11.3361 114.667 11.184C115.424 10.8701 116.244 10.7368 117.061 10.7947H134.565L137.472 0.29867H112.939C108.464 0.29867 105.344 0.832003 103.531 1.84"
                  fill="white"
                />
                <path
                  d="M36.032 0.29867L26.2027 35.7333C26.1464 36.018 26.0316 36.2878 25.8654 36.5256C25.6992 36.7634 25.4853 36.964 25.2374 37.1147C24.48 37.428 23.6603 37.5613 22.8427 37.504H20.0534C19.5075 37.5229 18.9658 37.4017 18.48 37.152C18.2879 37.0536 18.1181 36.9166 17.9814 36.7496C17.8446 36.5826 17.7439 36.3891 17.6854 36.1813C17.6542 36.0613 17.6416 35.9372 17.648 35.8133C17.653 35.6962 17.6709 35.5799 17.7013 35.4667L27.4507 0.29867H11.232L0.245361 39.9147C0.114958 40.3811 0.0362606 40.8604 0.0106935 41.344C-0.016246 41.8335 0.0304866 42.3243 0.149349 42.8C0.379735 43.6159 0.776927 44.3752 1.3158 45.0297C1.85468 45.6843 2.52353 46.2199 3.28003 46.6027C5.19001 47.5918 7.32233 48.073 9.47202 48H27.7493C32.224 48 35.344 47.4667 37.1627 46.464C38.9814 45.4613 40.2347 43.664 40.96 41.04L52.2613 0.304L36.032 0.29867Z"
                  fill="white"
                />
                <path
                  d="M151.093 33.328H162.005C167.195 33.328 170.773 30.5227 172.229 25.28L173.296 21.5947C174.539 17.1307 173.035 14.5333 168.88 13.7547L159.488 12.1973C158.656 12.0427 158.347 11.472 158.603 10.5973L159.173 8.464C159.256 8.10406 159.46 7.78367 159.752 7.55718C160.043 7.33069 160.404 7.21203 160.773 7.22133H166.379C167.211 7.22133 167.52 7.68533 167.312 8.464L166.848 10.128H175.984L176.555 8.048C177.957 2.80534 175.931 0.00533485 170.741 0.00533485H160.331C155.141 0.00533485 151.509 2.80534 150.107 8.048L149.173 11.248C147.925 15.7653 149.429 18.3573 153.637 19.088L162.981 20.688C163.808 20.7947 164.171 21.3653 163.915 22.288L163.184 24.832C163.103 25.1929 162.899 25.5145 162.607 25.7421C162.315 25.9696 161.954 26.089 161.584 26.08H155.451C154.619 26.08 154.256 25.6107 154.517 24.832L155.195 22.4427H146.059L145.28 25.248C143.824 30.4907 145.899 33.296 151.093 33.296"
                  fill="white"
                />
                <path
                  d="M180.832 33.0133H190.021L196.923 7.99467H205.851L207.931 0.309334H180.933L178.859 7.99467H187.733L180.832 33.0133Z"
                  fill="white"
                />
                <path
                  d="M211.525 33.0133L214.331 22.9973H218.741L221.232 33.0133H230.832L227.765 22.4747C229.281 22.0333 230.655 21.2022 231.75 20.0643C232.845 18.9264 233.623 17.5214 234.005 15.9893L236.395 7.424C237.589 3.06134 235.563 0.261332 231.205 0.261332H211.371L202.304 33.0133H211.525ZM218.459 7.94667H225.781C226.56 7.94667 226.848 8.416 226.661 9.24266L225.312 14.1227C225.231 14.4836 225.027 14.8052 224.735 15.0328C224.443 15.2603 224.082 15.3797 223.712 15.3707H216.405L218.459 7.94667Z"
                  fill="white"
                />
                <path
                  d="M243.637 33.0133L252.672 0.309334H243.483L234.453 33.0133H243.637Z"
                  fill="white"
                />
                <path
                  d="M258.901 33.0133L262.432 20.2987H267.104L270.944 33.0667H280.496L275.147 15.8827L288.491 0.309334H278.16L268.192 12.512H264.56L267.931 0.309334H258.747L249.712 33.0133H258.901Z"
                  fill="white"
                />
                <path
                  d="M314.859 7.99467L316.987 0.309334H292.277L283.243 33.0133H307.952L310.032 25.28H294.56L296.016 19.984H309.093L311.067 12.8213H297.989L299.339 7.99467H314.859Z"
                  fill="white"
                />
                <path
                  d="M138.96 48H303.781L305.819 40.6933H140.997L138.96 48Z"
                  fill="white"
                />
                <path
                  d="M46.7413 48H62.9547L68.3573 28.5227H87.2L90.112 18.0267H55.0507L46.7413 48Z"
                  fill="white"
                />
                <path
                  d="M95.0294 0.298666H59.968L57.056 10.7947H92.1174L95.0294 0.298666Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_12_2721">
                  <rect width="316.987" height="48" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </HStack>
      </Flex>
      <Stack
        h="100%"
        flexGrow={1}
        justifyContent="flex-end"
        gridGap={[0, 0, 2, 5]}
        display={['none', 'none', 'inherit']}
        direction="row"
        marginLeft={10}
      >
        {navItems.map((props) => (
          <NavLink key={props.href} {...props} />
        ))}
      </Stack>
    </Flex>
  );
};

export default DefaultNavigation;
