import { ComponentStyleConfig } from '@chakra-ui/theme';
import { shapiroSuper } from 'src/theme/Fonts/typeface';
import { Tier } from '__generated__/globalTypes';

export const RARITY_GRADIENT = {
  rare: 'linear(to-r, #F34E4B, #FF8F11)',
  legendary: 'linear(to-r, #F7A722, #E7FB02)',
  ultimate: 'linear(to-r, #3278FF, #D1EFFF)',
};

export const TextGradient: ComponentStyleConfig = {
  baseStyle: {
    bgGradient: 'linear(to-r, white, white)',
    bgClip: 'text',
  },
  variants: {
    default: {},
    ultimate: {
      bgGradient: RARITY_GRADIENT[Tier.ULTIMATE.toLowerCase()],
    },
    legendary: {
      bgGradient: RARITY_GRADIENT[Tier.LEGENDARY.toLowerCase()],
    },
    rare: {
      bgGradient: RARITY_GRADIENT[Tier.RARE.toLowerCase()],
    },
  },
  defaultProps: {
    variant: 'rare',
    fontFamily: shapiroSuper.fontFamily,
    fontSize: 'sm',
  },
};
