import * as React from 'react';
import {
  chakra,
  cx,
  forwardRef,
  useStyles,
  useTrackedOnClick,
} from 'src/theme';
import type { HTMLChakraProps, ThemingProps, TrackingProps } from 'src/theme';
import { useNav } from './';

export interface NavLinkProps
  extends TrackingProps,
    HTMLChakraProps<'a'>,
    ThemingProps<'a'> {
  children: React.ReactNode;
  className?: string;
  href?: string;
}

export const NavLink = forwardRef<NavLinkProps, 'a'>(
  (
    {
      className,
      href,
      trackingId,
      trackingProperties,
      onClick: originalOnClick,
      ...props
    },
    ref,
  ) => {
    const styles = useStyles();
    const { activeHref } = useNav();
    const isSelected = activeHref === href;
    const linkStyles = { ...styles.link, isSelected };

    const onClick = useTrackedOnClick({
      trackingId,
      trackingProperties,
      onClick: originalOnClick,
    });

    return (
      <chakra.a
        onClick={onClick}
        className={cx('AD-nav-link', className)}
        href={href}
        ref={ref}
        __css={linkStyles}
        aria-selected={isSelected}
        {...props}
      />
    );
  },
);
