import { shapiroFeather } from 'src/theme/Fonts/typeface';

export const Select = {
  parts: ['field', 'icon', 'option'],
  baseStyle: {
    field: {
      ...shapiroFeather,
    },
  },

  variants: {
    basic: {
      field: {
        bg: '#212127',
        color: '#F0F2FD',
        fontSize: 'sm',
      },
    },
  },
  defaultProps: {
    variant: 'basic',
  },
};
