import { useEffect } from 'react';
import { useIsWalletConnected, useSession } from '../../edge/session';

export const LOCALSTORAGE_EVENT_NAMESPACE = 'EVENT_ATTENDEE';
export const LOCALSTORAGE_EMAIL_TRACKED_NAMESPACE = 'EXECUTIVE_SUMMIT_ATTENDED';

const sendEmailToMailchimp = async (email) => {
  const data = await fetch('/api/trackEmail', {
    body: JSON.stringify({ email }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
  const resp = await data.json();

  if (!resp?.error) {
    // after successful response from tracking service remove event from localStorage
    localStorage.setItem(LOCALSTORAGE_EMAIL_TRACKED_NAMESPACE, 'true');
    return localStorage.removeItem(LOCALSTORAGE_EVENT_NAMESPACE);
  }

  const errorFromMailchimp = JSON.parse(
    resp?.error?.mailchimpResponseError ?? '{}',
  )?.title;
  // If email is already in list we can delete it from localstorage
  if (errorFromMailchimp === 'Member Exists') {
    localStorage.setItem(LOCALSTORAGE_EMAIL_TRACKED_NAMESPACE, 'true');
    return localStorage.removeItem(LOCALSTORAGE_EVENT_NAMESPACE);
  }
  console.log('Something went wrong', resp.error);
};

const useTrackUserEmailFromAttendedEvent = () => {
  const isAuthed = useIsWalletConnected();
  const { state: userSession } = useSession();

  useEffect(() => {
    (async () => {
      if (localStorage && isAuthed && userSession) {
        const eventName = localStorage.getItem(LOCALSTORAGE_EVENT_NAMESPACE);
        if (eventName) {
          const email = userSession?.context?.wallet?.addr ?? '';
          if (email) {
            return await sendEmailToMailchimp(email);
          }
          return console.log("Can't get user email");
        }
      }
    })();
  }, [isAuthed, userSession]);
};

export default useTrackUserEmailFromAttendedEvent;
