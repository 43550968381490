import { ErrorBoundary } from 'src/general/components/ErrorBoundary/ErrorBoundary';
import { ErrorPage } from 'src/general/components/ErrorPage/ErrorPage';

export function ErrorBoundaryPage(props) {
  return (
    <ErrorBoundary renderError={() => <ErrorPage title="Error" />}>
      {props.children}
    </ErrorBoundary>
  );
}
