import { useTranslation } from 'src/lib/translations';
import { Props as LinkProps } from 'src/general/components/Link/Link';
import {
  packs,
  dapperWallet,
  marketplace,
  blog,
  help,
} from 'src/modules/routes';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment/SegmentEventIDEnums';

export type NavItem = LinkProps & {
  isDecorated?: boolean;
  hasExternalLinkIcon?: boolean;
};

export const NavItems = () => {
  const { t } = useTranslation();

  const NAV_ITEMS: NavItem[] = [
    {
      children: t('NavigationBar.NavItem.packs'),
      href: packs(),
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_PACKS_CLICK,
    },
    {
      children: t('NavigationBar.NavItem.marketplace'),
      href: marketplace(),
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_MARKETPLACE_CLICK,
    },

    {
      children: t('NavigationBar.NavItem.help'),
      href: help,
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_HELP_CLICK,
    },
    {
      children: t('NavigationBar.NavItem.blog'),
      href: blog,
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_BLOG_CLICK,
    },
  ];

  return NAV_ITEMS;
};

export const AccountMenuItems = (isAuthed) => {
  const { t } = useTranslation();
  const ACCOUNT_MENU_ITEMS: NavItem[] = [
    {
      children: t('NavigationBar.NavItem.dapperWallet'),
      href: dapperWallet,
      target: '_blank',
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_DAPPER_WALLET_CLICK,
    },
  ];
  return isAuthed ? ACCOUNT_MENU_ITEMS : [];
};
