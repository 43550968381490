import { ReactElement } from 'react';
// import { NflAllDayLogo } from 'src/modules/nflArtwork';
import { Box } from 'src/theme';

function Takeover({ children }: { children: ReactElement }) {
  return (
    <Box p={4}>
      {/* <NflAllDayLogo />
      <Flex height="100%" flexDirection="column" mt={30}>
        <Box flexGrow={1}>{children}</Box>
      </Flex> */}
    </Box>
  );
}

export default Takeover;
