import { FC, ReactElement } from 'react';
import DefaultLayout from './Default';
import AltLayout from './Alt';
import Takeover from './Takeover';
import useTrackUserEmailFromAttendedEvent from '../../hooks/useTrackUserEmailFromAttendedEvent';

const layouts = {
  default: DefaultLayout,
  alt: AltLayout,
  takeover: Takeover,
};

type Props = {
  children: ReactElement;
};

const LayoutWrapper: FC<Props> = ({ children, ...rest }) => {
  useTrackUserEmailFromAttendedEvent();
  // to get the text value of the assigned layout of each component
  const Layout = layouts[(children.type as any).layout];
  // if we have a registered layout render children with said layout
  if (Layout != null) {
    return <Layout {...rest}>{children}</Layout>;
  }
  // if not render children with fragment
  return <DefaultLayout {...rest}>{children}</DefaultLayout>;
};

export default LayoutWrapper;
