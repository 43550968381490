import React from 'react';
import { forwardRef } from '@chakra-ui/react';
import { chakra } from '@chakra-ui/system';
import {
  useStyleConfig,
  HeadingProps as HeadingDeprecatedProps,
} from '@chakra-ui/react';

export const HeadingDeprecated = forwardRef(
  (
    { size, variant, as = 'h1', color, ...rest }: HeadingDeprecatedProps,
    ref,
  ) => {
    const styles = useStyleConfig('HeadingDeprecated', { size, variant });
    const element = as as string;
    const Heading = chakra[element];
    return <Heading {...rest} ref={ref} sx={{ ...styles, color }} />;
  },
);
