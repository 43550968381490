import { useAnalytics } from 'src/lib/segment';
import {
  Button as ChakraButton,
  ButtonProps,
  ComponentWithAs,
  forwardRef,
} from '@chakra-ui/react';
import { ComponentProps, useCallback } from 'react';

type ButtonComponent = ComponentWithAs<
  'button',
  ButtonProps & TrackingProperties
>;

type TrackingProperties = {
  trackingId?: string;
  trackingProperties?: object;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const Button = forwardRef<
  ButtonProps & TrackingProperties,
  ButtonComponent
>(
  (
    { trackingId, trackingProperties = {}, onClick = () => {}, ...props },
    ref,
  ) => {
    const { track } = useAnalytics();
    const hoistedOnClick = useCallback(
      (e) => {
        if (!!trackingId) {
          track(trackingId, trackingProperties);
        }
        onClick(e);
      },
      [track, trackingId, trackingProperties, onClick],
    );

    return <RawButton onClick={hoistedOnClick} ref={ref} {...props} />;
  },
);

export type Props = ComponentProps<typeof ChakraButton>;

const RawButton = forwardRef<Props, typeof ChakraButton>(
  ({ onClick, ...props }, ref) => {
    return <ChakraButton onClick={onClick} {...props} ref={ref} />;
  },
);
