import { extendTheme, ThemeOverride } from '@chakra-ui/react';
import { GlobalStyleProps, mode } from '@chakra-ui/theme-tools';
import { Button } from './theme/components/button';
import { Popover } from './theme/components/popover';
import { Menu, MenuList, MenuItem, MenuButton } from './theme/components/menu';
import { Text } from './theme/components/Text';
import { TextDeprecated } from './theme/components/TextDeprecated';
import { TextGradient } from './theme/components/TextGradient';
import { Heading } from './theme/components/Heading';
import { HeadingDeprecated } from './theme/components/HeadingDeprecated';
import { Modal } from './theme/components/modal';
import { Drawer } from './theme/components/drawer';
import { Tabs } from './theme/components/Tabs';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { SPACING_SCALE_ADDITIONS } from './theme/spacing';
import { Table } from './theme/components/Table';
import { Select } from './theme/components/select';
import { NavTheme as Nav } from '../modules/Nav';

import {
  shapiroFeather,
  shapiroLight,
  shapiroLightHeavy,
} from './Fonts/typeface';

export const breakpoints = {
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
};

const themeOverride: ThemeOverride = {
  config: {
    initialColorMode: 'dark',
  },
  breakpoints: createBreakpoints(breakpoints),
  space: { ...SPACING_SCALE_ADDITIONS },
  styles: {
    global: (props: GlobalStyleProps) => ({
      html: {
        minHeight: '100%', // forces the <html> element to take up the whole viewport
        display: 'flex',
        color: mode('gray.800', 'white')(props),
        backgroundColor: mode('black', 'black')(props),
      },
      body: {
        width: '100%',
        backgroundColor: mode('black', 'black')(props),
      },
      '#__next': {
        height: '100%',
      },

      '.html-formatted-description body': {
        ...shapiroFeather,
        color: 'white',
      },

      '.html-formatted-description ul': {
        marginLeft: '30px',
      },
      '.html-formatted-description h1': {
        fontSize: ['72px', '88px'],
        color: 'grey.text',
      },
      '.html-formatted-description h2': {
        fontSize: ['40px', '56px'],
        color: 'grey.text',
      },
      '.html-formatted-description h3': {
        fontSize: ['32px', '40px'],
        color: 'grey.text',
      },
      '.html-formatted-description h4': {
        fontSize: '32px',
        color: 'grey.text',
      },
      '.html-formatted-description p': {
        ...shapiroFeather,
        fontSize: '1rem',
        lineHeight: '1.5',
        margin: '0',
      },
    }),
  },
  colors: {
    // Temp from gridiron to start using them - murica!
    gray: {
      100: '#d6daeb',
      300: '#8a94a6',
      500: '#4B4D59',
    },
    // End temp

    brand: {
      primary: '#6F57FF',
      secondary: '#63EB24',
    },
    white: '#FFFFFF',
    dark: '#0e0e0d',
    black: '#000000',
    backgroundBlack: '#151515',
    backgroundRed: '#3A1D1C',
    green: '#0ADB6E',
    grey: {
      text: '#A7A7A7',
      dark: '#2F2D2E',
      middark: '#303032',
      highlight: '#2A2A2A',
      mid: '#A3A2A2',
      light: '#8A94A6',
      lightBackground: '#282828',
      0: '#D1D0D1',
      1: '#1F1F1F',
      2: '#363636',
      4: '#BDBDBD',
      5: '#676767',
      6: '#121212',
      7: '#CCCCCC',
      8: '#A7A7A7',
      9: '#8F8F8F',
      10: '#151515',
      divider: '#676767',
    },
    common: '#FFFFFF',
    legendary: '#F7A922',
    rare: '#F34E4B',
    ultimate: '#3278FF',
    alert: '#FF7369',
    soldout: '#F2CA62',
    expired: '#FFFFFF',
    success: '#6ADE85',
    overlay: '#0000004D',
    greenLight: '#0ADB6E',
    collectible: {
      owned: '#FFFFFF',
      holding: '#FFFFFF',
      forSale: '#6ADE85',
      hidden: '#5A9EF0',
      unavailable: '#525252',
    },
    ftue: {
      backgroundBlack: '#121212',
      backgroundGradient40: 'linear-gradient(0, #121212 40%, transparent)',
      backgroundGradient70:
        'linear-gradient(0, #121212 65%, rgb(0,0,0,0.5) 95%, rgb(0,0,0,0.1))',
    },
    badge: {
      success: {
        200: '#6ADE85',
      },
    },
    discord: {
      primary: '#5865F2',
    },
  },
  fonts: {
    heading: shapiroFeather.fontFamily,
    body: shapiroLight.fontFamily,
  },
  textStyles: {
    body48: {
      fontSize: '3rem',
      lineHeight: '150%',
    },
    body36: {
      fontSize: '2.25rem',
      lineHeight: '150%',
    },
    body32: {
      fontSize: '2rem',
      lineHeight: '150%',
    },
    body24: {
      fontSize: '1.5rem',
      lineHeight: '150%',
    },
    body20: {
      fontSize: '1.25rem',
      lineHeight: '150%',
    },
    body18: {
      fontSize: '1.125rem',
      lineHeight: '150%',
    },
    body16: {
      fontSize: '1rem',
      lineHeight: '150%',
    },
    body15: {
      fontSize: '0.9375rem',
      lineHeight: '18px',
    },
    body14: {
      fontSize: '0.875rem',
      lineHeight: '150%',
    },
    body13: {
      fontSize: '0.8125rem',
    },
    body12: {
      fontSize: '0.75rem',
      lineHeight: '150%',
    },
    body10: {
      fontSize: '0.625rem',
      lineHeight: '150%',
    },
    ribbonTitle: {
      ...shapiroFeather,
      fontSize: ['24px', '24px', '32px'],
      color: 'black',
      textTransform: 'uppercase',
    },
    ribbonCountdown: {
      ...shapiroFeather,
      fontSize: ['20px', '20px', '24px'],
      color: 'white',
      textTransform: 'uppercase',
    },
  },
  fontSizes: {
    '2xl': '1.5rem',
    '3xl': '2rem',
    '4xl': '2.5rem',
    '6xl': '3.5rem',
    '7xl': '5.5rem',
  },
  sizes: {
    container: {
      lg: '1224px',
      xl: '1440px',
    },
  },
  components: {
    Button,
    Nav,
    Container: {
      sizes: {
        xl: {
          maxW: 'container.xl',
        },
        lg: {
          maxW: 'container.lg',
        },
      },
      defaultProps: {
        size: 'xl',
      },
    },
    Divider: {
      baseStyle: {
        borderColor: 'grey.5',
        opacity: 1,
      },
    },
    Drawer,
    Heading,
    HeadingDeprecated,
    Input: {
      baseStyle: {
        margin: '12px 0',
      },
      variants: {
        modal: {
          field: {
            bg: 'dark',
          },
        },
      },
    },
    Tabs,
    Table,
    Text,
    TextDeprecated,
    TextGradient,
    Menu,
    MenuItem,
    MenuButton,
    MenuList,
    Modal,
    Select,
    Link: {
      baseStyle: {
        ...shapiroLight,
        fontWeight: 400,
        px: 4,
      },
      variants: {
        'nav-link': {
          py: 4,
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',

          _hover: {
            textDecoration: '',
          },
        },
        'nav-link-current': ({ theme }) => ({
          ...theme.components.Link.variants['nav-link'],
          bg: 'dark',
        }),
        'previous-breadcrumb': {
          fontSize: '14px',
          color: 'white',
          opacity: '.4',
        },
        'nav-link-menu-item': {
          ...shapiroFeather,
          textAlign: 'start',
          w: '100%',
          p: 0,
        },
        'mobile-nav-link-menu-item': {
          ...shapiroLightHeavy,
          textTransform: 'uppercase',
          fontSize: '20px',
          p: 0,
        },
      },
    },
    Popover,
  },
};
export default extendTheme(themeOverride);
