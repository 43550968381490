export const druk = { fontFamily: 'Druk, sans-serif' };
export const shapiroAir = { fontFamily: 'Shapiro-air, Helvetica, sans-serif' };
export const shapiroFeather = {
  fontFamily: 'Shapiro-feather, Helvetica, sans-serif',
};
export const shapiroLight = {
  fontFamily: 'Shapiro-light, Helvetica, sans-serif',
};
export const shapiroLightHeavy = {
  fontFamily: 'Shapiro-light-heavy, Helvetica, sans-serif',
};
export const shapiroHeavy = {
  fontFamily: 'Shapiro-heavy, Helvetica, sans-serif',
};
export const shapiroSuper = {
  fontFamily: 'Shapiro-super, Helvetica, sans-serif',
};
export const shapiro = {
  fontFamily: 'Shapiro, Helvetica, sans-serif',
};
