import { config } from '@onflow/fcl';

config()
  .put('app.detail.title', 'UFC Strike')
  .put('discovery.wallet', process.env.NEXT_PUBLIC_WALLET_DISCOVERY) // Configure FCL's Wallet Discovery mechanism
  .put(
    'discovery.wallet.method',
    process.env.NEXT_PUBLIC_WALLET_DISCOVERY_METHOD,
  )
  .put('accessNode.api', process.env.NEXT_PUBLIC_ACCESS_NODE)
  // replace:
  .put(
    '0xFungibleTokenContractAddress',
    process.env.NEXT_PUBLIC_FUNGIBLE_TOKEN_ADDRESS,
  )
  .put(
    '0xNonFungibleTokenContractAddress',
    process.env.NEXT_PUBLIC_NON_FUNGIBLE_TOKEN_ADDRESS,
  )
  .put(
    '0xNFTStorefrontContractAddress',
    process.env.NEXT_PUBLIC_NFT_STOREFRONT_ADDRESS,
  )
  .put('0xStrikeContractAddress', process.env.NEXT_PUBLIC_UFC_STRIKE_ADDRESS)
  .put('0xNFTContractAddress', process.env.NEXT_PUBLIC_NFT_ADDRESS)
  .put('0xPackNFTContractAddress', process.env.NEXT_PUBLIC_PACK_NFT_ADDRESS)
  .put(
    '0xDapperUtilityCoinContractAddress',
    process.env.NEXT_PUBLIC_DAPPER_UTILITY_COIN_ADDRESS,
  )
  .put('0xNFTName', process.env.NEXT_PUBLIC_NFT_NAME)
  .put('0xNFTStorageName', process.env.NEXT_PUBLIC_NFT_STORAGE_NAME)
  .put('0xIPackNFT', process.env.NEXT_PUBLIC_IPACK_NFT_ADDRESS);
