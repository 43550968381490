import * as React from 'react';
import { chakra, cx, forwardRef, useStyles } from 'src/theme';
import type { HTMLChakraProps, ThemingProps } from 'src/theme';

export interface NavListProps
  extends HTMLChakraProps<'ul'>,
    ThemingProps<'ul'> {
  children: React.ReactNode;
  className?: string;
}

export const NavList = forwardRef<NavListProps, 'ul'>(
  ({ className, ...props }, ref) => {
    const styles = useStyles();

    return (
      <chakra.ul
        className={cx('AD-nav-list', className)}
        ref={ref}
        __css={styles.list}
        {...props}
      />
    );
  },
);
