/**
 * These are additions to the default Chakra spacing scale.
 * @see https://chakra-ui.com/docs/theming/theme#spacing
 */

export const SPACING_SCALE_ADDITIONS = {
  13: '3.25rem',
  15: '3.75rem',
};

export type SpacingScaleAdditionsTypes = Record<
  keyof typeof SPACING_SCALE_ADDITIONS,
  string
>;
