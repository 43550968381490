import { path } from './utils';

export function siteUrl() {
  return process.browser ? window.location.origin : process.env.SITE_URL;
}

// NAVBAR LINKS

// DEFAULT
export const home = () => 'https://hype.ufcstrike.com/';

export const storefront = (params = {}) => {
  return path(['storefront'], params);
};
export const momentDetail = (id: string, params = {}) => {
  return path(['moments', id], params);
};

export const marketplace = (params = {}) => {
  return 'https://ufcstrike.com/p2pmarketplace';
};

const API_PATH = 'api';

export const featureFlagsPath = (params = {}) => {
  return path([API_PATH, 'flags'], params);
};

export const termsApiPath = (params = {}) => {
  return path([API_PATH, 'termsOfService'], params);
};

export const ftueApiPath = (params = {}) => {
  return path([API_PATH, 'ftue'], params);
};

export const signInPath = (params = {}) =>
  path(['api', 'auth', 'login'], params);

// TAKEOVERS
export const siteMaintenance = (params = {}) => {
  return path(['maintenance'], params);
};

/**
 * Footer - TO-DO: These links go nowhere at the moment.
 */

// BOTTOM_LINKS_PROPS
export const terms = 'https://ufcstrike.com/terms-of-use';
export const privacy = 'https://ufcstrike.com/privacy-notice';
export const ccpa = path(['ccpa']);
export const gdpr = path(['gdpr']);

// CONNECT_LINKS_PROPS
export const discord = 'https://discord.com/invite/UFCStrike';
export const instagram =
  'https://www.instagram.com/accounts/login/?next=/ufcstrike/';
export const twitter = 'https://twitter.com/UFCStrikeNFT';
export const statusPage = 'https://status.nflallday.com';
export const linkedIn = 'https://ca.linkedin.com/company/dapper-labs';
export const waitlist = 'https://nfl.dapperlabs.com/';
export const blog = 'https://blog.ufcstrike.com/';
export const basics = 'https://blog.nflallday.com/category/education';

// DAPPER
// @TODO: Update callback URL when user profiles are shareable
export const dapperSettings = `${process.env.DAPPER_ACCOUNT_URL}settings?action=clientAppCallback&clientAppID=${process.env.AUTH0_CLIENT_ID}&callbackUri=${process.env.NEXT_PUBLIC_SITE_URL}%2Fprofile`;
export const dapperWallet = `${process.env.DAPPER_ACCOUNT_URL}home`;
export const dapperWalletIdentityCheck = `${process.env.DAPPER_ACCOUNT_URL}security`;
export const help = 'https://support.ufcstrike.com/hc/en-us';
export const eliasSportsBureau = 'https://www.esb.com/';

// HELP_LINKS_PROPS
export const helpCenter = path(['helpCenter']);
export const faqs = path(['faqs']);
export const nflPrivacy = 'https://www.nfl.com/legal/privacy/';

// NFL_LINKS_PROPS
export const about = path(['about']);
export const developers = path(['developers']);
export const press = path(['press']);
export const investors = path(['investors']);
export const careers = path(['careers']);
export const hiring = path(['hiring']);

/**
 * Routes
 */

export const drops = (params = {}) => {
  return path(['drops'], params);
};

export const dropDetails = (id: string | number, params = {}) => {
  return path(['drops', id], params);
};

export const packs = (params = {}) => {
  return 'https://ufcstrike.com/drops';
};

export const packDetails = (flowId: string | number, params = {}) => {
  return path(['packs', flowId], params);
};

export const userProfile = (username: string, params = {}) => {
  return path(['user', username], params);
};

export const userProfilePacks = (username, params = {}) => {
  return path(['user', username, 'packs'], params);
};

export const listings = (params = {}) => {
  return path(['listings'], params);
};

export const listingsByEdition = (editionFlowID: number, params = {}) => {
  return path(['listings', editionFlowID], params);
};

export const momentNFTListings = (editionFlowID: number, params = {}) => {
  return path(['listings', editionFlowID, 'select'], params);
};

export const momentNFTListingsSalesHistory = (
  editionFlowID,
  type: 'recent' | 'top',
) => `/listings/${editionFlowID}/history/${type}`;

export const maintenance = () => '/maintenance';

export const settings = (params = {}) => {
  return path(['settings'], params);
};

export const error = (params = {}) => {
  return path(['error'], params);
};

export const errorPath = (errorCode: (string | number)[], params = {}) => {
  return path(['error', ...errorCode], params);
};

/**
 * Queue paths
 */
export const goToQueuePath = (params = {}) => {
  return path([API_PATH, 'queue', 'go-to-queue'], params);
};

export const cancelQueuePath = (params = {}) => {
  return path([API_PATH, 'queue', 'go-to-queue'], {
    ...params,
    action: 'cancel',
  });
};

export const queueCallbackPath = (params = {}) => {
  return path([API_PATH, 'queue', 'queue-callback'], params);
};

export const queueItUserTokenPath = (params = {}) => {
  return path([API_PATH, 'queue', 'queue-it-user-token'], params);
};

export const clearQueueCookiePath = (params = {}) => {
  return path([API_PATH, 'queue', 'clear-queue-id'], params);
};
