import { getItem } from 'src/general/utils/localStorage';
import { i18nInstance } from './i18n';

export const enhancedT = (key: string, interpolationObject?: any) => {
  const ssr = typeof window === 'undefined';
  const lokaliseOutput = !ssr && getItem('lokaliseOutput');
  const lokaliseKeys = !ssr && getItem('lokaliseKeys');

  const translation = i18nInstance.t(key, interpolationObject);
  if (lokaliseOutput === 'true') {
    console.log(`Lokalise [${key}]: ${translation}`);
  }
  if (lokaliseKeys === 'true') {
    return key;
  }

  // enable new line char from \\n that we receive in downloaded translations json
  return translation ? translation?.replace('\\n', '\n') : translation;
};
