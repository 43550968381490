import { Link } from 'src/general/components/Link';
import { useTranslation } from 'src/lib/translations';
import {
  Box,
  Container,
  Grid,
  IconDiscordLogo,
  IconInstagramLogo,
  IconTwitterLogo,
  Stack,
  StackDivider,
} from 'src/theme';
import {
  twitter,
  instagram,
  privacy,
  discord,
  terms,
  help,
} from 'src/modules/routes';
import { useSession } from 'src/edge/session';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment/SegmentEventIDEnums';
import { UFCStrikeUnderlinedLogo } from 'src/modules/ufcArtwork';

export const Footer = () => {
  return (
    <Box bg="backgroundBlack" padding={{ base: 6, md: 10 }} color="grey.8">
      <Container maxW="container.xl" padding={0}>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          divider={<StackDivider />}
          spacing="40px"
        >
          <Stack
            flexGrow={1}
            direction={{ base: 'column', md: 'row' }}
            justifyContent="space-between"
            spacing="40px"
          >
            <InternalLinksSection />
            <SocialLinksSection />
          </Stack>
          <CopyrightSection />
        </Stack>
      </Container>
    </Box>
  );
};

const InternalLinksSection = () => {
  const { t } = useTranslation();

  const internalLinks = [
    // {
    //   text: t('Footer.links.status'),
    //   url: statusPage,
    //   trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_FOOTER_STATUS_CLICK,
    // },
    // { text: t('Footer.links.blog'), url: blog },
    { text: t('Footer.links.help'), url: help },
    { text: t('Footer.links.privacy'), url: privacy },
    { text: t('Footer.links.terms'), url: terms },
  ];

  return (
    <Grid
      flexGrow={1}
      gridAutoFlow="column"
      gridTemplateRows="repeat(3, 1.5rem)"
      gap={4}
      placeItems="start"
    >
      {internalLinks.map(({ text, url }) => (
        <Link
          key={text}
          href={url}
          fontSize="sm"
          padding={0}
          // trackingId={trackingId}
        >
          {text}
        </Link>
      ))}
    </Grid>
  );
};

const SocialLinksSection = () => {
  const { t } = useTranslation();
  const { state: sessionState } = useSession();
  const { wallet } = sessionState.context;

  const socialLinks = [
    {
      text: t('Footer.social.Instagram'),
      url: instagram,
      SocialIcon: IconInstagramLogo,
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_FOOTER_INSTAGRAM_CLICK,
    },
    {
      text: t('Footer.social.Twitter'),
      url: twitter,
      SocialIcon: IconTwitterLogo,
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_FOOTER_TWITTER_CLICK,
      trackingProperties: { userID: wallet?.addr },
    },
    {
      text: t('Footer.social.Discord'),
      url: discord,
      SocialIcon: IconDiscordLogo,
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_FOOTER_DISCORD_CLICK,
      trackingProperties: { userID: wallet?.addr },
    },
  ];

  return (
    <Grid
      flexGrow={1}
      gridAutoFlow="column"
      gridTemplateRows={{ md: 'repeat(3, 1.5rem)' }}
      gap={6}
      placeItems="start"
      justifyContent={{ base: 'start', md: 'stretch' }}
    >
      {socialLinks.map(
        ({ text, url, SocialIcon, trackingId, trackingProperties }) => (
          <Link
            key={text}
            href={url}
            fontSize="sm"
            padding={0}
            whiteSpace="nowrap"
            trackingId={trackingId}
            trackingProperties={trackingProperties}
          >
            <SocialIcon boxSize={6} mr={{ md: 2 }} />
            <Box as="span" display={{ base: 'none', md: 'inline' }}>
              {text}
            </Box>
          </Link>
        ),
      )}
    </Grid>
  );
};

const CopyrightSection = () => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();
  return (
    <Stack
      direction="column"
      maxW={{ md: '300px' }}
      fontSize="xs"
      spacing="8px"
    >
      <UFCStrikeUnderlinedLogo />
      <p>{t('Footer.rights.licensed', { currentYear })}</p>
    </Stack>
  );
};
