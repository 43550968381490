import { ThemeOverride } from '@chakra-ui/react';
import { shapiroLight, shapiroFeather } from 'src/theme/Fonts/typeface';

/** @todo replace with colors from theme color palette, for now these are as designed */
export const Table: ThemeOverride['Table'] = {
  baseStyle: {
    ...shapiroLight,
    thead: {
      borderColor: '#585858',
    },
  },

  variants: {
    simple: {
      th: { color: '#909090' },
      td: {
        ...shapiroFeather,
        borderColor: '#393B45',
        color: '#D6DAEB',
        fontSize: 'sm',
      },
    },
  },
};
