export enum SEGMENT_EVENTS_ID_ENUM {
  HOMEPAGE_FEATURED_PACKS_INTERACTION = 'homepage-featured-packs-interaction',
  HOMEPAGE_CLOSE_PROMO_BANNER = 'homepage-close-promo-banner',
  PACKSTOREFRONT_HEADER_VIEW_PACK = 'packstorefront-header-viewpack',
  PACKSTOREFRONT_WEEKLY_DROP_VIEW_PACK = 'packstorefront-weeklydrop-viewpack',
  PACKSTOREFRONT_PREVIOUS_DROP_VIEW_PACK = 'packstorefront-previousdrop-viewpack',
  PACKSTOREFRONT_SOCIAL_DISCORD = 'packstorefront-social-discord',
  PACKSTOREFRONT_SOCIAL_TWITTER = 'packstorefront-social-twitter',
  PACKSTOREFRONT_SOCIAL_INSTAGRAM = 'packstorefront-social-instagram',
  PACKDETAILS_CALENDAR = 'packdetail-calendar',
  PACKDETAILS_ENTER_QUEUE = 'packdetail-enterqueue',
  PACKDETAILS_EXPLORE_PACKS = 'packdetail-explorepacks',
  PACKDETAILS_EDITIONS_LOAD_MORE = 'packdetail-editions-loadmore',
  PACKDETAILS_ELIGIBILITY_VIEW = 'packdetail-eligibility-view',
  NAVIGATION_FOOTER_STATUS_CLICK = 'navigation-footer-status-click',
  NAVIGATION_FOOTER_INSTAGRAM_CLICK = 'navigation-footer-instagram-click',
  NAVIGATION_FOOTER_TWITTER_CLICK = 'navigation-footer-twitter-click',
  NAVIGATION_FOOTER_DISCORD_CLICK = 'navigation-footer-discord-click',
  NAVIGATION_HEADER_SIGN_IN_CLICK = 'navigation-header-sign-in-click',
  NAVIGATION_HEADER_SIGN_OUT_CLICK = 'navigation-header-signout-click',
  NAVIGATION_HEADER_COLLECTION_CLICK = 'navigation-header-collection-click',
  NAVIGATION_HEADER_PACKS_CLICK = 'navigation-header-packs-click',
  NAVIGATION_HEADER_BLOG_CLICK = 'navigation-header-blog-click',
  NAVIGATION_HEADER_DISCORD_CLICK = 'navigation-header-discord-click',
  NAVIGATION_HEADER_MARKETPLACE_CLICK = 'navigation-header-marketplace-click',
  NAVIGATION_HEADER_HELP_CLICK = 'navigation-header-help-click',
  NAVIGATION_HEADER_MYPROFILE_CLICK = 'navigation-header-myprofile-click',
  NAVIGATION_HEADER_DAPPER_WALLET_CLICK = 'navigation-header-dapperwallet-click',
  NAVIGATION_HEADER_SETTINGS_CLICK = 'navigation-header-settings-click',
  PACKS_PURCHASE_TRANSACTION_MODAL_VIEW = 'pack-purchase-transaction-modalview',
  PACKS_PURCHASE_TRANSACTION_CONFIRM = 'pack-purchase-transaction-confirm',
  PACKS_OWNED_MODAL_VIEW = 'pack-owned-modalview',
  PACKS_OWNED_OPEN_CLAIMED = 'pack-owned-open-claimed',
  PACKS_OWNED_OPEN_LATER = 'pack-owned-open-later',
  PACKS_OWNED_REPLAYED = 'pack-owned-replayed',
  PACKS_OWNED_PROFILE = 'pack-owned-profile',
  PACKS_PURCHASE_TRANSACTION_CANCEL = 'pack-purchase-transaction-cancel',
  PACKS_OPENING_REVEAL_ALL = 'pack-opening-reveal-all',
  PACKS_OPENING_DONE_ACTION = 'pack-opening-done-action',
  PACKS_OPENING_MOMENT_REVEAL = 'pack-opening-moment-reveal',
  PACKS_OPENING_OPEN_PACK = 'pack-opening-open-pack',
  PROFILE_TAB_MOMENT = 'profile-tab-moment',
  PROFILE_TAB_PACKS = 'profile-tab-packs',
  PROFILE_MOMENT_CLICK = 'profile-moment-click',
  PROFILE_MOMENT_SHARE = 'profile-moment-share',
  PROFILE_PACKS_CLICK = 'profile-packs-click',
  PROFILE_PACKS_EMPTY_CTA = 'profile-packs-empty-cta',
  PROFILE_MOMENT_EMPTY_CTA = 'profile-moment-empty-cta',
  PROFILE_MOMENT_LOAD_MORE = 'profile-moment-loadmore',
  PROFILE_PACKS_LOAD_MORE = 'profile-packs-loadmore',
  MOMENT_DETAILS_SHARE_CLICK = 'moment-share',
  MOMENT_DETAILS_CAROUSEL_CLICK = 'moment-carousel-click',
  MOMENT_DETAILS_CAROUSEL_EXPAND = 'moment-carousel-expand',
  MOMENT_JUST_DROPPED_VIEW_PACK = 'moment-justdropped-viewpack',
  MAINTENANCE_LOGINS_DISPLAY = 'maintenance-logins-display',
  MAINTENANCE_ERROR_GETNOTIFIED = 'maintenance-error-getnotified',
  PACK_OPEN_SUCCESS = 'pack-open-success',
  PACK_TRANSACTION_SUCCESS = 'pack-transaction-success',
  LISTINGS_SELECT_LOAD_MORE = 'listings-select-loadmore',
  LISTING_SIGN_IN_TO_BUY = 'listing-sign-in-to-buy',
  FTUE_TOS_PAGEVIEW = 'ftue-tos-pageview',
  FTUE_TOS_MARKETINGOPTIN_CHECK = 'ftue-tos-marketingoptin-check',
  FTUE_TOS_AGREE_CLICK = 'ftue-tos-agree-click',
}
