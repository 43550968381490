import React, { Fragment, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useIsWalletConnected, useSession } from 'src/edge/session';
import { Link } from 'src/general/components/Link/Link';
import { useTranslation } from 'src/lib/translations';
import { home } from 'src/modules/routes';
import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  IconHamburgerMenu,
  Flex,
  Stack,
  TextDeprecated,
  useDisclosure,
  IconExternalLink,
} from 'src/theme';
import { NavItems, AccountMenuItems, NavItem } from './navItems';
import { IconRightArrow } from 'src/theme/Icon';
import { UFCStrikeUnderlinedLogo } from 'src/modules/ufcArtwork';

function MobileNavigation() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isAuthed = useIsWalletConnected();
  const { send, state: sessionState } = useSession();
  const router = useRouter();

  const wallet = sessionState?.context?.wallet;

  const { t } = useTranslation();

  const NAV_ITEMS: NavItem[] = NavItems();
  const ACCOUNT_MENU_ITEMS: NavItem[] = AccountMenuItems(isAuthed);

  // This will close the Drawer whenever the route change event fired, making sure that any links clicked in the Drawer cause it to
  // close. This is easier than passing `onClose()` to every Button and Link in this Drawer
  useEffect(() => {
    const handleRouteChange = () => {
      if (isOpen) {
        onClose();
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, onClose, isOpen]);

  return (
    <Fragment>
      <Box as="button" onClick={onOpen}>
        <IconHamburgerMenu w={6} h={6} />
      </Box>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody px={6} py={9}>
            <Flex
              direction="column"
              justifyContent="space-between"
              height="100%"
            >
              <Stack gridGap="4" direction="column">
                <Flex alignItems="center" flexDirection="row">
                  <DrawerCloseButton
                    ml={1}
                    position="inherit"
                    size="lg"
                    h="16px"
                    w="16px"
                  />
                  <Link alignSelf="center" href={home()}>
                    <UFCStrikeUnderlinedLogo />
                  </Link>
                </Flex>
                {wallet && (
                  <React.Fragment>
                    <Divider />
                  </React.Fragment>
                )}
                {ACCOUNT_MENU_ITEMS.map(
                  ({ children, trackingId, ...props }) => (
                    <Link
                      variant="nav-link-menu-item"
                      key={props.href}
                      trackingId={trackingId}
                      {...props}
                    >
                      {children}
                    </Link>
                  ),
                )}
                {isAuthed && (
                  <Fragment>
                    <TextDeprecated
                      pl="0"
                      pr="0"
                      onClick={() => {
                        send('SIGN_OUT');
                      }}
                    >
                      {t('NavigationBar.NavItem.signOut')}
                      <IconRightArrow pl="4px" h="24px" w="24px" />
                    </TextDeprecated>
                    <Divider />
                  </Fragment>
                )}
                {NAV_ITEMS.map(
                  ({
                    children,
                    trackingId,
                    trackingProperties,
                    hasExternalLinkIcon,
                    ...props
                  }) => (
                    <Link
                      key={props.href}
                      variant="mobile-nav-link-menu-item"
                      trackingId={trackingId}
                      trackingProperties={trackingProperties}
                      {...props}
                    >
                      {children}
                      {hasExternalLinkIcon && (
                        <IconExternalLink
                          h="16px"
                          w="16px"
                          ml={2}
                          color="grey.text"
                        />
                      )}
                    </Link>
                  ),
                )}
              </Stack>           
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Fragment>
  );
}

export default MobileNavigation;
