import { forwardRef, MouseEventHandler, useCallback } from 'react';
import { useAnalytics } from 'src/lib/segment';
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';

export interface TrackingProps {
  trackingId?: string;
  trackingProperties?: any;
}

export const useTrackedOnClick = ({
  onClick: originalOnClick,
  trackingId,
  trackingProperties,
}: TrackingProps & { onClick?: MouseEventHandler<HTMLAnchorElement> }) => {
  const { track } = useAnalytics();

  const onClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      if (trackingId) {
        track(trackingId, trackingProperties);
      }
      if (originalOnClick) {
        originalOnClick(e);
      }
    },
    [originalOnClick, track, trackingId, trackingProperties],
  );

  return onClick;
};

export interface LinkDeprecatedProps extends ChakraLinkProps, TrackingProps {}

// We need to forward the ref so that this component can be wrapped by NextJS's `Link`
// https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-function-component
export const LinkDeprecated = forwardRef<
  HTMLAnchorElement,
  LinkDeprecatedProps
>(
  (
    { trackingId, trackingProperties, onClick: originalOnClick, ...props },
    ref,
  ) => {
    const onClick = useTrackedOnClick({
      trackingId,
      trackingProperties,
      onClick: originalOnClick,
    });

    return <ChakraLink {...props} onClick={onClick} ref={ref} />;
  },
);
