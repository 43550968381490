// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
const logger = require('shared/logger');
const ignoreErrors = require('shared/sentry/ignoreErrors');

const maxBreadcrumbs = process.env.NODE_ENV === 'production' ? 50 : 1;
const sentryOptions = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  release: process.env.SENTRY_RELEASE, // @note: this var is added during the build phase (next.config.js)
  maxBreadcrumbs,
  attachStacktrace: true,
  tracesSampleRate: 0.25, // the % of requests that get a sentry-trace applied.
  integrations: [],
  denyUrls: [/\/storybook\//i],
  ignoreErrors,
  beforeSend(event) {
    // Do not send events to sentry from local dev
    if (process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'development') {
      logger.error(event);
      return;
    }
    // @NOTE: can also remove parts of event, like user info here.
    return event;
  },
};

// @note: this var is added during build phase (next.config.js)
if (
  process.env.NEXT_PUBLIC_SENTRY_ENABLED !== 'true' ||
  process.env.NODE_ENV === 'test' ||
  process.env.STORYBOOK
) {
  sentryOptions.enabled = false;
}

Sentry.init(sentryOptions);
