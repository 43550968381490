import { ThemeOverride } from '@chakra-ui/react';
import { shapiroFeather, shapiroLightHeavy } from 'src/theme/Fonts/typeface';

export const Text: ThemeOverride['components']['Text'] = {
  baseStyle: {
    color: 'white',
    fontFamily: 'Shapiro',
  },
  sizes: {
    lg: {
      fontSize: '1.125rem',
      lineHeight: '1.5em',
      letterSpacing: '-.16px',
    },
    md: {
      fontSize: '1rem',
      lineHeight: '1.375em',
      letterSpacing: '0',
    },
    sm: {
      fontSize: '0.875rem',
      lineHeight: '1.25em',
      letterSpacing: '.16px',
    },
    xs: {
      fontSize: '0.75rem',
      lineHeight: '1.125em',
      letterSpacing: '.32px',
    },
  },
  variants: {
    value: {
      fontWeight: 700,
      ...shapiroLightHeavy,
    },
    body: {
      fontWeight: 400,
      color: 'gray.100',
      ...shapiroFeather,
    },
    label: {
      fontWeight: 400,
      color: 'gray.300',
      ...shapiroFeather,
    },
    currency: {
      fontWeight: 700,
      color: 'gray.300',
      textTransform: 'uppercase',
      ...shapiroFeather,
    },
    link: {
      textDecoration: 'underline',
      color: 'white',
      ...shapiroFeather,
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'body',
  },
};
