import type { ComponentStyleConfig } from 'src/theme';

export const NavTheme: ComponentStyleConfig = {
  parts: ['nav', 'list', 'listItem', 'link'],
  baseStyle: {
    nav: {
      display: 'grid',
      gridColumnGap: 4,
      alignItems: 'center',
      gridTemplateAreas: "'start mid end'",
      gridTemplateColumns: 'max-content 1fr max-content',
      gridArea: 'actions',
      zIndex: 'docked',
    },
    list: {
      gridGap: 2,
      display: 'flex',
      gridTemplateColumns: 'minmax(min-content, max-content)',
    },
    listItem: {
      textTransform: 'uppercase',
      listStyleType: 'none',
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      _focus: {
        outline: 'none',
        boxShadow: 'outline',
      },
      _selected: {
        position: 'relative',
        _after: {
          content: '" "',
          position: 'absolute',
          height: '1px',
          left: 0,
          bottom: 0,
          right: 0,
          bg: 'white',
        },
      },
    },
  },
  sizes: {
    sm: {
      list: {
        fontSize: '0.75rem',
      },
    },
  },
  variants: {
    global: {
      nav: {
        borderBottom: '1px solid',
        borderColor: 'gray.900',
        px: 2,
      },
      link: {
        px: 5,
        height: '60px',
        _selected: {
          bg: '#151515',
        },
      },
    },
    sub: {
      list: {
        gridGap: 6,
      },
      link: {
        letterSpacing: '0.75px',
        fontSize: '0.875rem',
        height: '50px',
        color: 'grey.text',
        _focus: { outlineOffset: '1em' },
        _selected: {
          color: '#fff',
          _after: {
            borderWidth: 2,
          },
        },
      },
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'sub',
  },
};
