import { ThemeOverride } from '@chakra-ui/react';
import { shapiroHeavy } from 'src/theme/Fonts/typeface';

export const Menu = {
  parts: ['button'],
  baseStyle: {
    ...shapiroHeavy,
    bg: 'red',
    background: 'red',
    button: {
      _selected: {
        transform: 'rotate(180deg)',
      },
    },
  },
};

export const MenuList: ThemeOverride['components']['MenuList'] = {
  baseStyle: {
    ...shapiroHeavy,
    bg: 'red',
    background: 'red',
    backgroundColor: 'red',
  },
};

export const MenuItem: ThemeOverride['components']['MenuItem'] = {
  baseStyle: {
    ...shapiroHeavy,
    background: 'red',
  },
};

export const MenuButton: ThemeOverride['components']['MenuButton'] = {
  baseStyle: {
    ...shapiroHeavy,
    background: 'red',
  },
};
