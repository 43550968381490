import { useContext, useMemo } from 'react';
import { FS } from 'src/general/constants/finiteStates';
import { AnalyticsContext } from '@dapperlabs/react-analytics';
import { captureException } from 'src/lib/sentry';

function useAnalytics() {
  const { analyticsReady: isReady, track, trackPageView } = useContext(
    AnalyticsContext,
  );

  const state = useMemo(() => {
    if (!isReady) return FS.LOADING;
    else if (!!isReady) return FS.SUCCESS;
    return FS.IDLE;
  }, [isReady]);

  const trackClick = (trackingId, trackingProperties = {}) => {
    try {
      if (!!trackingId) {
        track(trackingId, trackingProperties);
      }
    } catch (error) {
      captureException(error);
    }
  };

  const trackPage = (trackingProperties = {}) => {
    try {
      trackPageView({
        userAgent: navigator.userAgent,
        ...trackingProperties,
      });
    } catch (error) {
      captureException(error);
    }
  };

  return {
    track: trackClick,
    trackPageView: trackPage,
    state,
    isAnalyticsReady: isReady,
  };
}

export { useAnalytics };
