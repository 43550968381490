import { ThemeOverride } from '@chakra-ui/react';
import { shapiroFeather, shapiroLightHeavy } from 'src/theme/Fonts/typeface';

export const Popover: ThemeOverride['components']['Popover'] = {
  baseStyle: {
    content: {
      mt: 2,
      px: { base: 6, md: 0 },
      width: { base: `calc(100vw - 15px)`, md: 'auto' },
      background: 'none',
      borderRadius: 'none',
      border: 'none',
      _focus: {
        boxShadow: 'none',
      },
    },
    header: {
      ...shapiroLightHeavy,
      color: 'black',
      fontSize: 'xs',
      background: 'white',
      borderBottom: 'none',
      borderBottomColor: 'white',
      textTransform: 'uppercase',
      pb: 0,
      pt: 6,
      px: 6,
    },
    body: {
      ...shapiroFeather,
      background: 'white',
      color: 'black',
      fontSize: 'xs',
      fontWeight: '400',
      pt: 4,
      pb: 6,
      px: 6,
    },
  },
};
