import * as Sentry from '@sentry/nextjs';
const merge = require('deepmerge');
const logger = require('shared/logger');

/**
 * returns a user id from the server-side session if one exists
 * does nothing on client.
 * @param {object} context
 */
async function getServerUserId(context) {
  if (process.browser || !context) return;
  try {
    const { decodeIdToken } = await import('shared/decodeToken');

    // @todo: update this to pass idToken…
    const decoded = await decodeIdToken(context.req);

    const userId = decoded.sub;
    return userId;
  } catch (error) {
    return null;
  }
}

/**
 * normalizeError
 *
 * this function attempts to normalize what we're capturing. For e.g. graphqQLError arrays do
 * not often cleanly ingest into sentry. This would normalize the error message and add some tags.
 *
 * @param {*} rawError - some entity that we want to capture. I.e. Error, Array, String, etc.
 * @param {*} rawContext - the context we pass to captureException to make it fancy
 */
function normalizeError(rawError, rawContext = {}) {
  try {
    // GraphQL error
    // these come in as arrays. Normalize the message as first error and then rest as extra-info.
    if (Array.isArray(rawError) && rawError?.[0]?.message) {
      // Instantiating a new error here to create a stack trace to try and make it more useful?
      const error = new Error(rawError[0].message);

      // Can't simply deepmerge the whole thing because of the `req` instance
      rawContext.errorInfo = merge(rawContext.errorInfo, {
        graphqlErrors: JSON.stringify(rawError, null, 2),
      });
      rawContext.tags = merge(rawContext.tags, { graphql: true });
      return { error, context: rawContext };
    }
  } catch (err) {
    // well, thats not good. Ignore and let the original capture through as planned.
  }

  return {
    error: rawError,
    context: rawContext,
  };
}

export const captureException = async function captureException(
  rawError,
  rawContext,
) {
  const { error, context } = normalizeError(rawError, rawContext);

  // Log rawError here for thre trace
  if (
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'development' &&
    process.env.NODE_ENV !== 'test'
  ) {
    console.error(rawError);
  }

  // This little flag allows us to throw errors as part of flow control that might have already been captured and
  // avoid double-capturing with potentially different stack traces leading to confusion.
  if (error?.noCapture) return;

  // avoid capturing the same error twice.
  if (error?.alreadyCaptured) return;
  try {
    error.alreadyCaptured = true;
  } catch (e) {}

  try {
    if (context?.ignoreInEnv === process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT)
      return;

    const serverUserId = context?.req ? await getServerUserId(context) : null;

    Sentry.withScope((scope) => {
      try {
        if (context?.level) {
          scope.setLevel(context.level);
        }
        if (context?.tags) {
          Object.entries(context.tags).forEach(([k, v]) => scope.setTag(k, v));
        }

        scope.setTag('ssr', !process.browser);

        if (error?.message) {
          // De-duplication currently doesn't work correctly for SSR / browser
          // errors so we force de-duplication by error message if it is present
          scope.setFingerprint([error.message]);
        }

        if (error.statusCode) {
          scope.setExtra('statusCode', error.statusCode);
        }

        if (context) {
          const { req, res, errorInfo, query, pathname } = context;

          if (res && res.statusCode) {
            scope.setExtra('statusCode', res.statusCode);
          }

          if (process.browser) {
            scope.setExtra('query', query);
            scope.setExtra('pathname', pathname);
          } else {
            if (req) {
              scope.setExtra('url', req.url);
              scope.setExtra('method', req.method);
              scope.setExtra('headers', req.headers);
              scope.setExtra('params', req.params);
              scope.setExtra('query', req.query);
            }

            // On server-side we take session cookie directly from request and add
            // it to the user scope
            if (serverUserId) {
              scope.setUser({ id: serverUserId });
            }
          }

          Object.entries(errorInfo || {}).forEach(([k, v]) =>
            scope.setExtra(k, v),
          );
        }
        const errorId = Sentry.captureException(error);

        // On the server, lets log a simple message for visibility
        if (!process.browser) {
          const message = error.message || '[no message]';
          logger.error({ message, errorId });
        }
      } catch (error) {
        // @note: fail silently in browser in case Sentry fails
        if (!process.browser) logger.error(error);
      }
    });
  } catch (error) {
    if (!process.browser) logger.error(error);
  }
};

/**
 * For use outside of application context (i.e. server.js)
 */
export const simpleCapture = (exception, tags) => {
  try {
    Sentry.withScope((scope) => {
      Object.entries(tags || {}).forEach(([k, v]) => scope.setTag(k, v));
      Sentry.captureException(exception);
    });
  } catch (error) {
    logger.error(error);
  }
};

/**
 * Only works on client.
 * Sets user on scope for every sentry error.
 * @param {string} id
 */
export const setExceptionUser = function setExceptionUser(id) {
  if (!process.browser) return;
  Sentry.configureScope((scope) => scope.setUser({ id }));
};

export const SEVERITY = Sentry.Severity;
