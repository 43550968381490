export const getItem = (key) => {
  let item;
  try {
    item = window.localStorage.getItem(key);
    return item === null ? null : JSON.parse(item);
  } catch (_) {
    return null;
  }
};

export const setItem = (key, value) => {
  if (typeof value === 'undefined') return;
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (_) {
    return;
  }
};

export const removeItem = (key) => {
  try {
    window.localStorage.removeItem(key);
  } catch (_) {
    return;
  }
};
