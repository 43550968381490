import { shapiroFeather } from 'src/theme/Fonts/typeface';

export const Modal = {
  parts: ['content', 'header'],
  baseStyle: {
    overlay: {
      backgroundColor: 'overlay',
    },
    dialog: {
      ...shapiroFeather,
      borderRadius: [0, 8],
      backgroundColor: 'dark',
      border: 'none',
      minWidth: ['328px', '380px', '380px', '576px'],
      margin: [0, 0, 'auto'],
    },
    body: {
      marginTop: ['6', '6'],
      marginBottom: ['6', '6'],
    },
  },
  variants: {
    takeover: {
      body: {
        marginLeft: ['3', '12'],
        marginTop: ['6', '12'],
        marginRight: ['3', '12'],
        marginBottom: ['6', '12'],
      },
      closeButton: {
        top: '8',
        right: ['2', '8'],
      },
      header: {
        padding: '0',
      },
    },
    listing: {
      dialog: {
        minWidth: ['328px', '520px', '750px', '750px'],
      },
    },
  },
};
