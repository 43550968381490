import { createContext, useContext } from 'react';
import type { ReactElement } from 'react';

interface NavContextI {
  activeHref?: string;
}

interface NavContextProviderProps {
  activeHref: string;
  children: ReactElement;
}

export const NavContext = createContext<NavContextI>({ activeHref: null });

export const useNav = () => {
  const context = useContext(NavContext);

  if (context === undefined) {
    throw new Error('useNav must be used within a NavContextProvider');
  }

  return context;
};

export const NavContextProvider = ({
  activeHref,
  ...rest
}: NavContextProviderProps) => (
  <NavContext.Provider value={{ activeHref }} {...rest} />
);
