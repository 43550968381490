import { home } from 'src/modules/routes';
import {
  Button,
  Container,
  LinkDeprecated,
  HeadingDeprecated,
} from 'src/theme';

export const ErrorPage = ({ title }: { title: string }) => {
  return (
    <Container
      display="flex"
      height="100%"
      flexDirection="column"
      gridGap={4}
      justifyContent="center"
    >
      <HeadingDeprecated size="md">{title}</HeadingDeprecated>
      <Button
        as={LinkDeprecated}
        mt={2}
        size="md"
        variant="solid"
        href={home()}
      >
        Back Home
      </Button>
    </Container>
  );
};
